import { useMutation } from '@tanstack/react-query'
import { updateLTAWorkStatus } from 'api/lta'

export function useUpdateLTAWorkStatusMutation(
  workerId: string,
  shiftId: number,
  options?: {
    onSuccess?: () => void
    onError?: (error: Error) => void
  }
) {
  return useMutation({
    mutationFn: (status: string) =>
      updateLTAWorkStatus(workerId, shiftId, status),
    ...options,
  })
}
