import React from 'react'

import AddListingBonus from '../features/add_listing_bonus'
import ListingsPending from './listing_pending'
import ListingAdCreate from './listing_ad_create'
import ListingAdSetStatus from './listing_ad_set_status'
import ListingAds from './listing_ads'
import { Box, Flex } from '@workwhile/ui'

const ListingPage = () => (
  <Box>
    <ListingAdCreate />
    <br />
    <br />
    <hr />
    <Flex maxHeight={500}>
      <Box width={[1, 200]}>
        <ListingAdSetStatus />
      </Box>
      <Box flex={1} overflow={'auto'}>
        <ListingAds />
      </Box>
    </Flex>
    <br />
    <br />
    <hr />
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '500px',
      }}
    >
      <AddListingBonus />
    </div>
    <br />
    <br />
    <hr />
    <br />
    <br />
    <ListingsPending />
    <br />
  </Box>
)

export default ListingPage
