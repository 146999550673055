import { Table } from '@workwhile/ui'
import { User } from 'api/typings'
import React, { useMemo } from 'react'

interface Props {
  user: User
}

export const UserTutorials = ({ user }: Props) => {
  const data = useMemo(() => {
    return user.workerTutorials
  }, [user.workerTutorials])

  const columns = useMemo(
    () => [
      {
        header: 'Tutorial ID',
        accessorKey: 'tutorialId',
      },
      {
        header: 'Quiz Score',
        accessorKey: 'quizScore',
      },
    ],
    []
  )

  return (
    <>
      {user.workerTutorials && user.workerTutorials.length > 0 ? (
        <Table data={data} columns={columns} />
      ) : (
        <p>User has no completed tutorials</p>
      )}
    </>
  )
}
