import { useQuery } from '@tanstack/react-query'
import { searchUserKey } from './keys'
import { searchUsers } from 'api/user'

export function useSearchUsers({
  keyword,
  enabled,
}: {
  keyword: string
  enabled?: boolean
}) {
  return useQuery({
    queryKey: searchUserKey(keyword),
    queryFn: () => searchUsers(keyword),
    enabled,
  })
}
