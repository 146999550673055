import React, { useState } from 'react'
import { User } from 'api/typings'
import { Box, Button, Checkbox, Flex, Message, Text } from '@workwhile/ui'
import { useUploadProfilePicMutation } from 'queries/user'
import { format, parseISO } from 'date-fns'

export function ProfilePicture({
  profilePicUrl,
  verifiedSelfieUrl,
  profilePicVerifiedAt,
  profilePicManuallyVerifiedBy,
  id,
  refreshData,
}: Pick<
  User,
  | 'id'
  | 'profilePicUrl'
  | 'verifiedSelfieUrl'
  | 'profilePicVerifiedAt'
  | 'profilePicManuallyVerifiedBy'
> & {
  refreshData: () => void
}) {
  const [file, setFile] = useState<File | null>(null)
  const [shouldManuallyVerify, setShouldManuallyVerify] = useState(false)
  const {
    mutate: uploadPhoto,
    error,
    isPending,
  } = useUploadProfilePicMutation(id, shouldManuallyVerify, {
    onSuccess: refreshData,
  })

  const getForceVerifyDescription = () => {
    if (verifiedSelfieUrl) {
      return (
        <Text my={0} mt={1}>
          If the worker is unable to verify their photo, and you're sure this
          photo matches the worker's{' '}
          <a target="_blank" href={verifiedSelfieUrl} rel="noreferrer">
            Persona Verified Selfie
          </a>
          , you may check this box.
        </Text>
      )
    }

    return (
      <Text>
        This worker does not have a Persona Verified Selfie. You should only
        Force Verify if you are 100% sure that this photo accurately represents
        the worker.
      </Text>
    )
  }

  return (
    <>
      <Box backgroundColor={'offWhite'} p={2} mb={2}>
        <Text fontWeight="bold">Last Verified:</Text>
        {profilePicVerifiedAt ? (
          <>
            <Text mb={2}>
              {format(
                parseISO(profilePicVerifiedAt),
                'iii MMM do yyyy  hh:mma'
              )}{' '}
              {
                new Intl.DateTimeFormat('en-US', {
                  timeZoneName: 'short',
                  hour: 'numeric', // Required for some environments to include the timezone
                })
                  .format(parseISO(profilePicVerifiedAt))
                  .split(' ')[2]
              }
            </Text>
            <Text fontWeight="bold">Verified by:</Text>
            <Text mb={2}>
              {profilePicManuallyVerifiedBy?.id
                ? `(Ops) ${profilePicManuallyVerifiedBy?.name}(${profilePicManuallyVerifiedBy.id})`
                : 'User'}
            </Text>
          </>
        ) : (
          'Never Verified'
        )}
      </Box>
      <Flex>
        {profilePicUrl ? (
          <Box
            width={110}
            backgroundColor={'offWhite'}
            style={{
              backgroundImage: `url(${profilePicUrl})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex', // Set Box as a flex container
              flexDirection: 'column', // Arrange children in a column
              justifyContent: 'flex-end', // Align children to the bottom
            }}
          />
        ) : null}
        <Box flex={1} ml={2} p={3} backgroundColor={'offWhite'}>
          <input
            disabled={isPending}
            type={'file'}
            accept={'image/*'}
            onChange={(e) => setFile(e.target.files[0])}
            style={{ width: 200 }}
          />
          <Box mt={3} mb={2}>
            <Checkbox
              value={shouldManuallyVerify ? 'checked' : 'unchecked'}
              onChange={(checked) => {
                setShouldManuallyVerify(checked)
              }}
            >
              <Text fontWeight={2}>Force Verify</Text>
              <Box>{getForceVerifyDescription()}</Box>
            </Checkbox>
          </Box>
          {error ? (
            <Message mt={3} variant={'error'}>
              {error.message}
            </Message>
          ) : null}
          <Button
            mt={3}
            width={150}
            loading={isPending}
            disabled={!file}
            onClick={() => uploadPhoto(file)}
          >
            Upload
          </Button>
        </Box>
      </Flex>
    </>
  )
}
