import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

import graphql from '../../graphql'
import Loading from '../Shared/loading'
import { useNavigate, useParams, useLocation } from 'react-router'

const shiftAnalyticsQuery = (shiftId) => {
  return `
        admin { 
            shiftAnalytics (shiftId: ${shiftId}) {
                shift {id, workersNeeded, payRate, company {name}, position {name}}
                listingImpressions,
                supplyEstimation,
                numWorkersNotified,
                numWorkersOpenedApp,
            }
        }`
}

const ShiftAnalytics = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const [loading, setLoading] = React.useState(false)
  const [shiftAnalytics, setShiftAnalytics] = React.useState(null)
  const [shiftId, setShiftId] = React.useState(
    searchParams.get('shiftId') ? searchParams.get('shiftId') : null
  )
  const [needToFetchOnFirstLoad, setNeedToFetchOnFirstLoad] = React.useState(
    Array.from(searchParams).length > 0
  )

  const getShiftAnalytics = () => {
    setLoading(true)
    graphql(shiftAnalyticsQuery(shiftId))
      .then((response) => {
        if (response) {
          setShiftAnalytics(response.data.data.admin.shiftAnalytics)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const params = new URLSearchParams()
    if (shiftId) {
      params.set('shiftId', String(shiftId))
    }
    navigate({ search: params.toString() })
    if (needToFetchOnFirstLoad) {
      getShiftAnalytics()
      setNeedToFetchOnFirstLoad(false)
    }
  }, [shiftId])

  const getShiftDetailView = (shift) => {
    return (
      <div>
        <p>
          {shift.position.name} at {shift.company.name} ({shift.id})
        </p>
        <p>Workers Needed: {shift.workersNeeded}</p>
        <p>Pay Rate: {shift.payRate}</p>
      </div>
    )
  }

  const getColumns = () => {
    return [
      {
        dataField: 'shift',
        text: 'Shift Details',
        headerStyle: { width: '6em', fontSize: 12 },
        formatter: (cell, row) => getShiftDetailView(row.shift),
      },
      {
        dataField: 'supplyEstimation',
        text: 'Supply Estimate',
        headerStyle: { width: '6em', fontSize: 12 },
        formatter: (cell, row) => <p>Estimate: {row.supplyEstimation}</p>,
      },
      {
        dataField: 'numWorkersOpenedApp',
        text: 'Workers Opened app in last 30 days',
        headerStyle: { width: '6em', fontSize: 12 },
        formatter: (cell, row) => <p> {row.numWorkersOpenedApp}</p>,
      },
      {
        dataField: 'numWorkersNotified',
        text: 'Workers notified',
        headerStyle: { width: '6em', fontSize: 12 },
        formatter: (cell, row) => <p>{row.numWorkersNotified}</p>,
      },
      {
        dataField: 'listingImpressions',
        text: 'Listing Impressions',
        headerStyle: { width: '6em', fontSize: 12 },
        formatter: (cell, row) => <p>Impressions: {row.listingImpressions}</p>,
      },
    ]
  }

  // Return a div with an input field for shift_id, a button to submit form and a BootstrapTable to display the results.
  return (
    <div
      style={{
        display: 'flex',
        width: '100vh',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <form
        className="mt-2"
        onSubmit={(e) => {
          e.preventDefault()
          getShiftAnalytics()
        }}
        style={{ display: 'flex', flex: 1, marginBottom: '20px' }}
      >
        <input
          className="input"
          name="shiftId"
          value={shiftId}
          onChange={(e) => setShiftId(e.target.value)}
          type="number"
          placeholder="Shift Id"
        />
        <button type="submit" className="ml-2">
          Get Shift Analytics
        </button>
      </form>
      {loading && <Loading style={{ width: '100%' }} />}
      {shiftAnalytics && !loading && (
        <BootstrapTable
          keyField="shift"
          data={[shiftAnalytics]}
          columns={getColumns()}
        />
      )}
    </div>
  )
}

export default ShiftAnalytics
