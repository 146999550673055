import React, { useState } from 'react'

interface Props {
  cell
  row
  onSaveCallback: (newValue) => void
}
const EditableCellFormatter = ({ cell, row, onSaveCallback }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(cell)
  const [originalValue, setOriginalValue] = useState(cell) // To store the original value before editing

  const handleEdit = () => {
    setOriginalValue(value)
    setIsEditing(true)
  }

  const handleSave = () => {
    onSaveCallback(value)
    setIsEditing(false)
  }

  const handleChange = (e) => {
    console.log('handleChange')
    setValue(e.target.value)
  }

  const handleCancel = () => {
    setValue(originalValue) // Restore the original value
    setIsEditing(false)
  }

  return isEditing ? (
    <div>
      <textarea
        rows={5}
        cols={50}
        value={value}
        onChange={handleChange}
        className="form-control mb-2"
      />
      <button className="btn btn-primary btn-sm" onClick={handleSave}>
        Save
      </button>
      <button className="btn btn-secondary btn-sm" onClick={handleCancel}>
        Cancel
      </button>
    </div>
  ) : (
    <span onClick={handleEdit}>{value}</span>
  )
}

export default EditableCellFormatter
