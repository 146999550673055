import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { WorkShift } from '../Shared/common_defs'
import { defs } from '../Shared/defs'
import { device } from '../Shared/device'

interface Props {
  name: string
  lastSenderName?: string
  message?: string
  shift?: WorkShift
  sentAt?: string
}

const ConversationThumbnail = ({
  name,
  lastSenderName,
  message,
  shift,
  sentAt,
}: Props) => {
  return (
    <Container>
      <div style={{ fontSize: 14 }}>{name}</div>
      <div style={{ fontSize: 7, fontStyle: 'italic' }}>
        {moment(sentAt).format('ddd Do h:mma')}
      </div>
      <div style={{ fontSize: 10 }}>
        {lastSenderName}: {message.substring(0, 24)}...
      </div>
      {shift && (
        <div style={{ fontSize: 9, display: 'flex', flexWrap: 'wrap' }}>
          <ShiftDetail>shift_id:{shift.id}</ShiftDetail>
          <ShiftDetail>{shift?.position?.name}</ShiftDetail>
          <ShiftDetail>{shift?.location?.address?.city}</ShiftDetail>
          <ShiftDetail>{moment(shift?.startsAt).format('ddd Do')}</ShiftDetail>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  @media ${device.smallerThanMobileL} {
    display: none;
  }
`

const ShiftDetail = styled.div`
  margin-right: ${defs.marginXS};
  font-style: italic;
`

export default ConversationThumbnail
