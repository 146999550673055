import React from 'react'
import api from '../../../api'
import graphql from '../../../graphql'
import Loading from '../../Shared/loading'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Field,
  Heading,
  Input,
} from '@workwhile/ui'

const INITIAL_STATE = {
  company_name: '',
  company_id: '',
  contact_email: '',
  companies: [],
  invite_link: '',
  already_sent: null,
  invoice: false,
  w2: false,
  logo: null,
  loading: false,
  searchTerm: '',
}

class Company extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.fetchCompanies()
  }

  fetchCompanies = () => {
    this.setState({ loading: true })
    api.get('/admin/companies').then((res) => {
      this.setState({
        companies: res.data.companies,
        loading: false,
      })
    })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        logo: URL.createObjectURL(event.target.files[0]),
      })
    }
  }

  uploadLogo = async (companyId) => {
    if (!this.state.logo) return

    const data = { url: this.state.logo }

    try {
      await api.put(`/admin/company/${companyId}/logo`, data)
      this.setState({ logo: null })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  onCreateCompany = (event) => {
    event.preventDefault()
    const { company_name, logo, invoice, w2 } = this.state

    if (!company_name || !logo) {
      alert('Please add a company name and LOGO URL')
      return
    }

    const data = {}
    if (invoice) {
      data['set_to_invoice'] = true
    }
    if (w2) {
      data['set_to_w2'] = true
    }

    api
      .post(`/admin/company/create/${company_name}`, data)
      .then((res) => {
        this.fetchCompanies()
        alert(`Company created! ID: ${res.data.company_id}. See table below`)
        this.uploadLogo(res.data.company_id)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  onInviteUserToCompany = (event) => {
    event.preventDefault()
    const { company_id, contact_email } = this.state
    const formattedContactEmail = contact_email.trim()

    api
      .post(`/admin/company/invite/${company_id}/${formattedContactEmail}`)
      .then((res) => {
        const { link, already_sent } = res.data
        this.setState({ invite_link: link, already_sent })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  handleSwitchCompany = async (companyId) => {
    try {
      await api.put(`/admin/company/${companyId}/switch`)
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        // On mobile devices, replace current tab's location
        window.location.replace('https://business.workwhilejobs.com')
      } else {
        // On non-mobile devices, open a new tab
        window.open('https://business.workwhilejobs.com', '_blank')
      }
    } catch (error) {
      console.error('Error switching company:', error)
    }
  }

  handleSearch = (event) => {
    this.setState({ searchTerm: event.target.value })
  }

  getFilteredCompanies = () => {
    const { companies, searchTerm } = this.state
    const term = searchTerm.toLowerCase()
    const isNumericSearch = !isNaN(Number(term))

    return companies.filter((company) => {
      const nameMatch = company.name.toLowerCase().includes(term)
      const idMatch = isNumericSearch && company.id.toString().includes(term)
      return nameMatch || idMatch
    })
  }

  render() {
    const {
      loading,
      company_name,
      company_id,
      contact_email,
      invite_link,
      already_sent,
      invoice,
      w2,
      logo,
      searchTerm,
    } = this.state

    const columns = getColumns(this.handleSwitchCompany)
    const filteredCompanies = this.getFilteredCompanies()

    return (
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p={4}
        >
          <Box flex="1" mr={2}>
            <Heading>Create a Company</Heading>
            <Card width="100%" as={'form'} onSubmit={this.onCreateCompany}>
              <Field label={'Enter Company Name'}>
                <Input
                  name="company_name"
                  value={company_name}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Company Name"
                />
              </Field>
              <Box mb={2}>
                <Checkbox
                  defaultChecked={invoice}
                  onChange={(checked) => this.setState({ invoice: checked })}
                >
                  Check if company will be invoiced
                </Checkbox>
              </Box>
              <Checkbox
                defaultChecked={invoice}
                onChange={(checked) => this.setState({ w2: checked })}
              >
                Check if company is W-2{' '}
              </Checkbox>
              <Heading level={4}>Add a logo URL</Heading>
              <p>
                On google images search for "company name" logo png. For the one
                you like right click and select "Copy image address"
              </p>
              <Box my={3}>
                <Input
                  block
                  name="logo"
                  type="text"
                  value={logo || ''}
                  onChange={this.onChange}
                  placeholder={'Logo URL'}
                />
              </Box>
              {logo ? (
                <img
                  id="target"
                  src={logo}
                  style={{ marginBottom: 20, marginTop: 5, maxWidth: '100%' }}
                />
              ) : null}
              <Button type="submit">Create Company</Button>
            </Card>
          </Box>

          <Box flex="1" ml={2}>
            <Heading>Invite to company</Heading>
            <Card
              width="100%"
              as={'form'}
              onSubmit={this.onInviteUserToCompany}
            >
              <Field label={'Contact Email'}>
                <Input
                  name="contact_email"
                  value={contact_email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Contact Email"
                />
              </Field>
              <Field label={'Company Id'}>
                <Input
                  name="company_id"
                  value={company_id}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Company ID"
                />
              </Field>
              <Button type="submit">Invite</Button>
              <Box mt={3}>
                {invite_link !== '' && (
                  <Box display="flex" flexDirection="column" p={2} bg="#fafafa">
                    {already_sent ? (
                      <Box mb={2}>
                        THERE IS ALREADY AN EXISTING INVITE ASSOCIATED WITH THIS
                        EMAIL IN THE COMPANY_INVITE TABLE. We did NOT send
                        another email to the email address you provided. The
                        link will ONLY WORK if the company_invite record lists
                        the intended email and intended company in the same row.
                        If you previously invited this email to a different
                        company the link will NOT allow the user to complete the
                        sign up process. Here's the link:{' '}
                      </Box>
                    ) : (
                      <Box mb={2}>
                        Invite generated. Link to share with employer:
                      </Box>
                    )}
                    <Box>{invite_link}</Box>
                  </Box>
                )}
              </Box>
            </Card>
          </Box>
        </Box>

        <Box mt={5}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Heading>Companies</Heading>
              <Box my={4}>
                <Input
                  className="search-box"
                  type="text"
                  placeholder="Search companies..."
                  value={searchTerm}
                  onChange={this.handleSearch}
                />
              </Box>

              <BootstrapTable
                striped
                hover
                keyField="id"
                data={filteredCompanies}
                columns={columns}
              />
            </>
          )}
        </Box>
      </Box>
    )
  }
}

const getColumns = (handleSwitchCompany) => [
  {
    dataField: 'id',
    text: 'ID',
  },
  {
    dataField: 'switch',
    text: 'Switch Company',
    formatter: (cell, row) => (
      <Button variant="xsmall" onClick={() => handleSwitchCompany(row.id)}>
        Switch
      </Button>
    ),
  },
  {
    dataField: 'name',
    text: 'Name',
  },
  {
    dataField: 'take_percentage_estimate',
    text: 'Take Percentage',
    formatter: (cell) => `${cell}%`,
  },
  {
    dataField: 'charge_upfront',
    text: 'On Invoice?',
    formatter: (cell) => <div>{cell ? 'No' : 'Yes'}</div>,
  },
  {
    dataField: 'invoice_due_days',
    text: 'Invoice Due Days',
    formatter: (cell) => <div>{cell || 'N/A'}</div>,
  },
  {
    dataField: 'pending_invites',
    text: 'Pending Invites',
    formatter: (cell) => (
      <div>
        {cell.map((invite) => (
          <p key={invite.email}>{invite.email}</p>
        ))}
      </div>
    ),
  },
]

export default Company
