import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

const INITIAL_STATE = {
  user_id: '',
  user_id_notification: '',
}

class Logs extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (event) => {
    const { user_id } = this.state
    window.open(
      `https://console.cloud.google.com/logs/query;query=resource.type%3D"gae_app" log_name%3D"projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured" jsonPayload.message.type%3D"app_request_log" jsonPayload.message.user_id%3D${user_id};timeRange=PT24H;summaryFields=jsonPayload%2Fmessage%2Fstatus_code,jsonPayload%2Fmessage%2Fmethod,jsonPayload%2Fmessage%2Fpath:false:32:beginning?project=workwhile-247820&folder=true&organizationId=136924707921&query=`,
      '_blank'
    )
  }

  onSubmitNotification = (event) => {
    const { user_id_notification } = this.state
    window.open(
      `https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%20log_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%20jsonPayload.message.type%3D%22notify_send2%22%20jsonPayload.message.user_id%3D${user_id_notification};timeRange=PT3H;summaryFields=jsonPayload%252Fmessage%252Fstatus_code,jsonPayload%252Fmessage%252Fmethod,jsonPayload%252Fmessage%252Fpath,jsonPayload%252Fmessage%252Fmessage,jsonPayload%252Fmessage%252Ferrors:false:32:beginning?project=workwhile-247820&folder=true&organizationId=136924707921&query=`,
      '_blank'
    )
  }

  render() {
    const { user_id, user_id_notification } = this.state

    return (
      <div className="mt-5">
        <form onSubmit={this.onSubmit} className="inline-form">
          <input
            className="input"
            name="user_id"
            value={user_id}
            onChange={this.onChange}
            type="text"
            placeholder="User ID"
          />
          <button type="submit" className="submit-button">
            Open Link to user errors
          </button>
        </form>
        <button
          className="submit-button"
          onClick={() =>
            window.open(
              'https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%0Alog_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%0AjsonPayload.message.type%3D%22error_post_data%22;timeRange=PT24H;summaryFields=jsonPayload%252Fmessage%252Fstatus,jsonPayload%252Fmessage%252Fuser_id,jsonPayload%252Fmessage%252Fpath:false:32:beginning?project=workwhile-247820',
              '_blank'
            )
          }
        >
          See ALL errors across the system
        </button>

        <form onSubmit={this.onSubmitNotification} className="inline-form">
          <input
            className="input"
            name="user_id_notification"
            value={user_id_notification}
            onChange={this.onChange}
            type="text"
            placeholder="User ID"
          />
          <button type="submit" className="submit-button">
            Open Link to notification logs
          </button>
        </form>
      </div>
    )
  }
}

export default Logs
