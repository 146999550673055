import React from 'react'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import graphql from '../../graphql'
import { Heading } from '@workwhile/ui'

const INITIAL_STATE = {
  listingAds: [],
}

class ListingAds extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
    this.options = {
      defaultSortName: 'id', // default sort column name
      defaultSortOrder: 'desc', // default sort order
    }
  }

  componentDidMount() {
    this.fetchListingAds()
  }

  fetchListingAds() {
    graphql(`
      admin { 
        listingAds {
          id, 
          about,
          address { street, city, state, zip, lat, long }, 
          companyName, 
          companyLogoUrl, 
          positionName,
          pay,
          status
        }
      }`)
      .then((response) => {
        console.log('response: ', response)
        this.setState({
          listingAds: response.data.data.admin.listingAds,
          fetching: false,
        })
      })
      .catch((error) => {
        console.log('error')
      })
  }

  getColumns() {
    return [
      {
        dataField: 'id',
        text: 'Id',
      },
      {
        dataField: 'company',
        text: 'Company',
        formatter: (cell, row) => (
          <p>
            {row.companyName},{' '}
            <a href={row.companyLogoUrl} target="_blank">
              Logo Url
            </a>
          </p>
        ),
      },
      {
        dataField: 'status',
        text: 'Status',
      },
      {
        dataField: 'positionName',
        text: 'Position',
      },
      {
        dataField: 'address',
        text: 'Address',
        formatter: (cell, row) => (
          <p>
            {cell.street}, {cell.city}, {cell.state}, {cell.zip}
          </p>
        ),
      },
      {
        dataField: 'pay',
        text: 'Pay',
      },
      {
        dataField: 'about',
        text: 'About',
      },
    ]
  }

  render() {
    const { listingAds } = this.state

    const sortedListingAds = listingAds.sort((a, b) => (a.id > b.id ? -1 : 1))

    return (
      <div style={{ flexDirection: 'column' }}>
        <Heading>Listing Ads</Heading>
        <div className="fixed-height-table-500">
          <BootstrapTable
            striped
            hover
            options={this.options}
            keyField="name"
            data={sortedListingAds}
            columns={this.getColumns()}
          />
        </div>
      </div>
    )
  }
}

export default ListingAds
