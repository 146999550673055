import React, { Component } from 'react'
import { withRouter } from 'lib/withRouter'
import { PasswordForgetLink } from '../PasswordForget'
import './landing.css'
import * as ROUTES from '../../constants/routes'
import { compose } from 'recompose'
import {
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth'

import { Box, Button, Field, Heading, Input, Message } from '@workwhile/ui'

const Landing = () => (
  <Box p={4}>
    <Heading>WorkWhile Admin - Sign In</Heading>
    <SignInForm />
    <PasswordForgetLink />
  </Box>
)

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  mfaVerificationId: '',
  verificationCode: '',
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.setUpRecaptcha()
  }

  setUpRecaptcha = () => {
    const auth = getAuth()
    this.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container-id',
      { size: 'invisible' }
    )
  }

  sendMFAVerification = async (error) => {
    // send MFA code
    try {
      const auth = getAuth()
      const resolver = getMultiFactorResolver(auth, error)
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        },
        this.recaptchaVerifier
      )
      this.setState({
        mfaVerificationId: verificationId,
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  verifyMFACode = async () => {
    const { error, mfaVerificationId, verificationCode } = this.state
    if (!error || !verificationCode || !mfaVerificationId) {
      return
    }
    try {
      const auth = getAuth()
      const resolver = getMultiFactorResolver(auth, error)
      const cred = PhoneAuthProvider.credential(
        mfaVerificationId,
        verificationCode
      )
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      await resolver.resolveSignIn(multiFactorAssertion)
      this.setState({ ...INITIAL_STATE })
      this.props.history.push(ROUTES.LANDING)
    } catch (error) {
      this.setState({ error })
    }
  }

  onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { email, password } = this.state
    const auth = getAuth()
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.LANDING)
      })
      .catch((error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.sendMFAVerification(error)
        }
        this.setState({ error })
      })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error, mfaVerificationId, verificationCode } =
      this.state

    const isInvalid = password === '' || email === ''

    return (
      <Box>
        <div id={'recaptcha-container-id'} />
        {mfaVerificationId ? (
          <form>
            <input
              className="input"
              name="verificationCode"
              value={verificationCode}
              onChange={this.onChange}
              type="text"
              placeholder="Verification Code"
            />
            <button
              type={'button'}
              onClick={this.verifyMFACode}
              className="submit-button"
            >
              Verify
            </button>
          </form>
        ) : (
          <Box width={[1, 400]} as={'form'} onSubmit={this.onSubmit}>
            <Field label={'Enter your email address'}>
              <Input
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
            </Field>
            <Field label={'Enter your password'}>
              <Input
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
            </Field>
            {error ? (
              <Message my={2} variant="error">
                {error.message}
              </Message>
            ) : null}
            <Button width={150} disabled={isInvalid} type="submit">
              Sign In
            </Button>
          </Box>
        )}
        {error && <p>{error.message}</p>}
      </Box>
    )
  }
}

const SignInForm = compose(withRouter)(SignInFormBase)

export { SignInForm }

export default Landing
