import graphql from 'graphql'
import { path } from 'ramda'
import { Location } from 'api/typings'

export async function getCompanyLocations(companyId: number) {
  const response = await graphql(`
      admin { 
        companies(ids: [${companyId}]) {
            locations {
                id, name, address {city, state, street, unit, zip, lat, long, timezone}
            }
        }
      }`)

  return path(
    ['data', 'data', 'admin', 'companies', 0, 'locations'],
    response
  ) as Location[]
}
