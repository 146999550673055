import React, { useEffect, useMemo, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import graphql from '../../graphql'
import { getRowStyles } from '../utils/util'
import { getShiftColumns, shiftsQuery } from './shifts'
import Loading from '../Shared/loading'
import { Box, Button, Heading, Input } from '@workwhile/ui'
import { useSearchParams } from 'react-router-dom'

const SingleShift = () => {
  const [params, setParams] = useSearchParams()
  const [fetching, setFetching] = useState(false)
  const [shift, setShift] = useState(null)
  const [columns] = useState(getShiftColumns('upcoming', null))
  const [inputShiftId, setInputShiftId] = useState(params.get('shiftId') || '')

  const searchShiftId = useMemo(() => {
    return params.get('shiftId') || null
  }, [params])

  useEffect(() => {
    if (searchShiftId) {
      fetchShift(Number(searchShiftId))
    }
  }, [searchShiftId])

  const fetchShift = (shiftId: number) => {
    setFetching(true)
    graphql(shiftsQuery({ shiftId }))
      .then((response) => {
        setShift(response.data.data.admin.shifts[0])
      })
      .finally(() => {
        setFetching(false)
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputShiftId(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setParams({ shiftId: inputShiftId })
  }

  return (
    <Box>
      <Box as={'form'} onSubmit={handleSubmit}>
        <Heading level={3}>Shift Information</Heading>
        <Input
          type="text"
          placeholder="Shift ID"
          value={inputShiftId}
          onChange={handleInputChange}
        />
        <Button disabled={fetching} ml={2} type={'submit'}>
          Load Shift
        </Button>
        {fetching ? <Loading /> : null}
      </Box>
      {shift ? (
        <Box mt={3}>
          <BootstrapTable
            striped
            hover
            keyField="id"
            data={[shift]}
            columns={columns}
            rowStyle={getRowStyles}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default SingleShift
