import { useQuery } from '@tanstack/react-query'
import { getCompanyLocations } from 'api/company'
import { companyLocationsKey } from 'queries/review/keys'

export function useCompanyLocations(companyId: number | null) {
  return useQuery({
    queryKey: companyLocationsKey(companyId),
    queryFn: () => getCompanyLocations(companyId),
    enabled: !!companyId, // This ensures the query only runs when shiftId is not null
    staleTime: 0,
  })
}
