import React from 'react'
import { updateMinPayPolicy } from '../../actions/company_actions'
import BootstrapTable from 'react-bootstrap-table-next'
import moment from 'moment'
interface Props {
  companyData
}
const MinPayPolicy = ({ companyData }: Props) => {
  const today = new Date().toISOString().split('T')[0]
  const MIN_MINUTES = 240
  const [creatingNewPolicy, setCreatingNewPolicy] = React.useState(false)
  // The current policy minimum is 240.
  const [newMinMinutes, setNewMinMinutes] = React.useState(MIN_MINUTES)
  const [newStartDate, setNewStartDate] = React.useState(today)

  if (companyData === null || companyData.minPayPolicy === null) {
    return <>No pay policy. Default is to pay for whole shift.</>
  }
  const columns = [
    {
      dataField: 'minPayLength',
      text: 'Minimum Pay Length (minutes)',
      editable: false,
    },
    {
      dataField: 'startsAt',
      text: 'Policy Started At:',
      editable: false,
      formatter: (cell, row) => {
        const date = new Date(cell)
        return date.toLocaleDateString()
      },
    },
  ]

  function onNewPolicySubmit() {
    if (newMinMinutes < 240) {
      // No alert needed, the input will display an error.
      return
    }
    if (newStartDate < today) {
      // No alert needed, the input will display an error. Str comparison works here b/c lexicographic ordering.
      return
    }
    let startDateWithTZ = moment(newStartDate)
      .tz('America/Los_Angeles')
      .format('YYYY-MM-DDTHH:mm:ssZ')
    updateMinPayPolicy(
      companyData.id,
      newMinMinutes,
      startDateWithTZ,
      (response) => {
        if (response.status === 200) {
          alert('Successfully updated min pay policy.')
          setCreatingNewPolicy(false)
        } else {
          alert('Failed to update min pay policy.')
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={companyData.minPayPolicies}
        columns={columns}
        striped
        rowStyle={{ fontSize: 14 }}
      />
      {creatingNewPolicy ? null : (
        <button
          type="submit"
          className="btn btn-primary mr-2"
          onClick={() => {
            setCreatingNewPolicy(true)
          }}
        >
          Add new Policy
        </button>
      )}
      {creatingNewPolicy ? (
        <form
          onSubmit={(e) => {
            // Attach this to the form so that the user can submit with enter and it doesn't reload the entire page.
            e.preventDefault()
            onNewPolicySubmit()
          }}
        >
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Minimum Minutes to Pay For</label>
              <input
                type="number"
                className="form-control"
                value={newMinMinutes}
                onChange={(e) => setNewMinMinutes(parseInt(e.target.value))}
                min={MIN_MINUTES}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>
                New Policy Start Date. Policy will start at 12:00 AM on the
                selected date in PT.
              </label>
              <input
                type="date"
                className="form-control"
                value={newStartDate}
                onChange={(e) => setNewStartDate(e.target.value)}
                min={today}
              />
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
            <button
              type="reset"
              className="btn btn-secondary"
              onClick={() => {
                setCreatingNewPolicy(false)
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : null}
      <br />
    </>
  )
}
export default MinPayPolicy

export function hasCustomPolicy(companyData) {
  // Method to calculate whether there is a custom min pay policy.
  return (
    companyData.minPayPolicies !== null && companyData.minPayPolicies.length > 0
  )
}
