import React from 'react'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import api from '../../api'
import graphql from '../../graphql'
import PaymentChargeItem from './payment_charge_item'
import Button from 'react-bootstrap/Button'
import Moment from 'react-moment'
import { Heading } from '@workwhile/ui'

const INITIAL_STATE = {
  receipts: [],
}

class Payments extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.fetchReceipts()
  }

  fetchReceipts() {
    this.setState({ fetching: true })
    graphql(`
      admin { 
        charges2 {
          id, totalAmount, shift { id }
          items {
            id
            description
            quantity
            unitAmount
            amount
            itemType
          }
          company {name, id, chargeUpfront}
          shift {id, position {name}, startsAt}
        }
      }`).then((response) => {
      console.log('response: ', response)
      this.setState({
        receipts: response.data.data.admin.charges2,
        fetching: false,
      })
    })
  }

  getColumns = (chargeId, isChargeUpfront) => {
    return [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        headerStyle: { width: '4em' },
      },
      {
        dataField: 'itemType',
        text: 'item type',
      },
      {
        dataField: 'description',
        text: 'Description',
      },
      {
        dataField: 'quantity',
        text: 'Quantity',
      },
      {
        dataField: 'unitAmount',
        text: 'Unit',
        headerStyle: { width: '7em' },
      },
      {
        dataField: 'amount',
        text: 'Amount',
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: (cell, row) => {
          return (
            <>
              <button
                key={'delete_' + row.id}
                onClick={() => this.deleteChargeItem(chargeId, row.id)}
              >
                Delete
              </button>
              {!isChargeUpfront && (
                <button
                  key={'credit_' + row.id}
                  onClick={() => this.creditChargeItem(chargeId)}
                >
                  Issue Credit
                </button>
              )}
            </>
          )
        },
      },
    ]
  }

  actOnCharge(chargeId, action) {
    api
      .put(`/admin/charges/${chargeId}`, {
        finalized: action === 'finalized' ? true : false,
      })
      .then((response) => {
        console.log(response)
        alert('action successful: ' + action)
        this.fetchReceipts()
      })
      .catch((error) => {
        console.log('ERROR OCCURED: ', error)
      })
  }

  deleteChargeItem = (chargeId, itemId) => {
    api
      .delete(`/admin/charges/${chargeId}/items/${itemId}`)
      .then((response) => {
        console.log(response)
        alert('item deleted')
        this.fetchReceipts()
      })
      .catch((error) => {
        console.log('ERROR OCCURED: ', error)
      })
  }

  creditChargeItem = (itemId) => {
    // API endpoint is called 'discount' but is a full amount credit.
    api
      .post(`/admin/charges/items/${itemId}/discount`)
      .then((response) => {
        console.log(response)
        alert('item credited')
        this.fetchReceipts()
      })
      .catch((error) => {
        alert('ERROR OCCURED: ', error)
        console.log('ERROR OCCURED: ', error)
      })
  }

  render() {
    const { receipts, fetching } = this.state

    if (!receipts || Object.keys(receipts).length === 0) {
      return null
    }

    if (fetching) {
      return (
        <div className="mt-5 pt-5 p-4">
          <Heading>Loading....</Heading>
        </div>
      )
    }

    console.log('receipts', receipts)

    return (
      <div className="mt-5 p-4">
        {receipts.map((receipt, index) => (
          <div className="mb-5 mt-2">
            <Heading level={3}>
              Receipt: {receipt.id} for {receipt.company.name}(
              {receipt.company.id}) for ${receipt.totalAmount}
            </Heading>
            {receipt.shift && (
              <p className="mt-2">
                Shift {receipt.shift.id}, {receipt.shift.position.name}, starts
                at{' '}
                <Moment format="YYYY-MM-DD h:mm A">
                  {receipt.shift.startsAt}
                </Moment>
              </p>
            )}
            <BootstrapTable
              striped
              hover
              keyField="id"
              data={receipt.items}
              columns={this.getColumns(
                receipt.id,
                receipt.company.chargeUpfront
              )}
            />
            <PaymentChargeItem chargeId={receipt.id} />
            <Button
              key={'finalize_' + receipt.id}
              onClick={() => this.actOnCharge(receipt.id, 'finalized')}
              className="mr-2"
            >
              Finalize
            </Button>
            <Button
              key={'void_' + receipt.id}
              onClick={() => this.actOnCharge(receipt.id, 'void')}
            >
              Void
            </Button>
            <hr
              style={{
                color: '#000000',
                backgroundColor: '#000000',
                height: 5,
              }}
              className="mb-5 mt-5"
            />
          </div>
        ))}
      </div>
    )
  }
}

export default Payments
