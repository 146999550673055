import { defs } from './defs'

export const sharedStyles = {
  collapsibleSectionContainer: {
    flex: 1,
  },
  collapsibleSection: {
    borderRadius: defs.borderRadiusM,
    padding: defs.paddingM,
    marginRight: defs.marginM,
    marginBottom: defs.marginM,
    borderWidth: 1,
    borderColor: defs.mediumBlack,
    backgroundColor: defs.white,
    fontSize: 12,
    minWidth: 300,
  },
  collapsibleSectionTitleRow: {
    display: 'flex',
    flex: 1,
    flexdirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  collapsibleSectionTitleRowIcon: {
    marginLeft: 'auto',
    fontSize: 16,
  },
}
