import React, { useState, useEffect } from 'react'
import '../../components/features/Commands/Commands.css'
import api from '../../api'
import Command from '../../components/features/Commands/Command'
import { Heading } from '@workwhile/ui'

type CommandType = {
  name: string
}
type GroupedCommandsType = Record<string, CommandType[]>

export function LTACommands() {
  const [commands, setCommands] = useState([])
  const [searchTerm] = useState('lta') // search for all commands with the substring 'lta'
  const [filteredCommands, setFilteredCommands] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCommands()
  }, [])

  const fetchCommands = async () => {
    api
      .get('/admin/commands')
      .then((response) => {
        setCommands(response.data)
      })
      .catch((error) => {
        alert(`Error fetching commands. ${error}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const results = commands.filter((command) =>
      command.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredCommands(results)
  }, [searchTerm, commands])

  const groupedCommands: GroupedCommandsType = filteredCommands.reduce(
    (groups, command) => {
      const groupName = command.app_group
      if (!groups[groupName]) {
        groups[groupName] = []
      }
      groups[groupName].push(command)
      return groups
    },
    {}
  )

  return (
    <div>
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        Object.entries(groupedCommands).map(
          ([appGroupName, appGroupCommands]) => (
            <div key={appGroupName}>
              {appGroupCommands.map((command) => (
                <Command key={command.name} command={command} />
              ))}
              <hr />
            </div>
          )
        )
      )}
    </div>
  )
}
