import React from 'react'
import { User } from '../Shared/common_defs'
import CollapsibleSection from '../Shared/CollapsibleSection'
import UserEventLog from './UserEventLog'

interface Props {
  user: User
}

const UserEvents = ({ user }: Props) => {
  return (
    <CollapsibleSection title="Event Logs">
      <UserEventLog user={user} />
    </CollapsibleSection>
  )
}

export default UserEvents
