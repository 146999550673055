import React, { useState } from 'react'
import { Roster, WorkShift } from '../../Shared/common_defs'
import moment from 'moment'
import { RosterWorkers } from './RosterWorkers'

/**
 * Callout the rosters (i.e. Lists) which the shift is restricted to (if any)
 */

interface Props {
  shift: WorkShift
  rosters: Roster[]
  rostersHoldExpiresAt: string | null
}

const ShiftRosters = ({ shift, rosters, rostersHoldExpiresAt }: Props) => {
  // Flatten the array of workers from all rosters into a single array
  const allWorkers = rosters.reduce(
    (acc, roster) => acc.concat(roster.workers),
    []
  )
  // Calculate the number of unique workers
  const uniqueWorkerCount = new Set(allWorkers.map((worker) => worker.id)).size
  return (
    <>
      <b>
        Restricted to {uniqueWorkerCount} workers{' '}
        {rostersHoldExpiresAt
          ? `until ${moment
              .tz(rostersHoldExpiresAt, moment.tz.guess(true))
              .format('ddd MMM Do, h:mma z')}`
          : `indefinitely`}
        :{' '}
      </b>
      <br />
      {rosters.map((roster) => (
        <RosterItem key={roster.id} shift={shift} roster={roster} />
      ))}
      <br />
    </>
  )
}

export default ShiftRosters

const RosterItem = ({
  roster,
  shift,
}: {
  roster: Roster
  shift: WorkShift
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <a onClick={handleToggle} style={{ color: 'blue', cursor: 'pointer' }}>
        - {roster.name}(id:{roster.id})({roster.workers.length} workers)
      </a>
      {isOpen ? (
        <div style={{ marginLeft: 15 }}>
          <RosterWorkers shift={shift} roster={roster} />
        </div>
      ) : null}
    </div>
  )
}
