import React, { useEffect, useState } from 'react'
import { User } from '../Shared/common_defs'
import { workColumns, userWorkQuery } from '../Shared/work'
import BootstrapTable from 'react-bootstrap-table-next'
import graphql from '../../graphql'
import { defs } from '../Shared/defs'
import { formatStatusOptionsForSelect } from '../utils/util'
import { Flex, Input, Text, Select, Box, Button } from '@workwhile/ui'

interface Props {
  user: User
}

const groupingOptions = [
  { label: 'All', value: null },
  { label: 'upcoming', value: 'upcoming' },
  { label: 'ongoing', value: 'ongoing' },
  { label: 'past', value: 'past' },
]

const UserWorkList = ({ user }: Props) => {
  const [submittingWorkRequest, setSubmittingWorkRequest] = useState(false)
  const [work, setWork] = useState([])
  const [limit, setLimit] = useState(10)
  const [grouping, setGrouping] = useState(null)
  const [status, setStatus] = useState(null)

  useEffect(() => {
    requestWork()
  }, [])

  const requestWork = (e?) => {
    if (e) {
      e.preventDefault()
    }
    setWork([]) // clear the current work list
    setSubmittingWorkRequest(true)
    graphql(userWorkQuery(user.id, limit, grouping, status))
      .then((response) => {
        const workResponse = response.data.data.admin.user.work
        if (workResponse) {
          setWork(workResponse)
        }
        setSubmittingWorkRequest(false)
      })
      .catch((error) => {
        setSubmittingWorkRequest(false)
      })
  }

  const searchGroup = (
    <Flex alignItems={'center'} my={3}>
      <Text mr={2}>Entries:</Text>
      <Input
        width={50}
        name="limit"
        type="number"
        value={limit}
        onChange={(e) => setLimit(parseInt(e.target.value))}
        placeholder="Entries"
      />
      <Box ml={3} width={200}>
        <Select
          isSearchable
          isClearable
          options={groupingOptions}
          styles={customStyles}
          name="grouping"
          placeholder="Grouping"
          onChange={(option) => {
            if (option) setGrouping((option as { value: string }).value)
            else setGrouping(null)
          }}
        />
      </Box>
      <Box mx={3} width={200}>
        <Select
          isSearchable
          isClearable
          options={formatStatusOptionsForSelect()}
          styles={customStyles}
          name="status"
          placeholder="Status"
          onChange={(option) => {
            if (option) setStatus((option as { value: string }).value)
            else setStatus(null)
          }}
        />
      </Box>
      <Button
        width={200}
        loading={submittingWorkRequest}
        onClick={() => requestWork()}
      >
        Request Data
      </Button>
    </Flex>
  )

  return (
    <div>
      {searchGroup}
      {work && work.length > 0 ? (
        <>
          <BootstrapTable
            striped
            keyField="shift_id"
            data={work}
            columns={workColumns(requestWork)}
            rowStyle={{ fontSize: 12 }}
          />
        </>
      ) : (
        <p>User is not associated with any shifts</p>
      )}
    </div>
  )
}

export const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
}

export default UserWorkList
