import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import * as ROUTES from '../../constants/routes'
import { Box, Button, Field, Input, Message, Heading } from '@workwhile/ui'

const PasswordForgetPage = () => (
  <Box p={4}>
    <Heading>Password Reset</Heading>
    <PasswordForgetForm />
  </Box>
)

const INITIAL_STATE = {
  email: '',
  error: null,
}

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    const { email } = this.state
    const auth = getAuth()
    sendPasswordResetEmail(auth, email)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch((error) => {
        this.setState({ error })
      })
    event.preventDefault()
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error } = this.state
    const isInvalid = email === ''

    return (
      <Box width={[1, 400]} as={'form'} onSubmit={this.onSubmit}>
        <Field label={'Enter your email address'}>
          <Input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </Field>
        {error ? (
          <Message my={3} variant="error">
            {error.message}
          </Message>
        ) : null}
        <Button disabled={isInvalid} type="submit">
          Reset My Password
        </Button>
      </Box>
    )
  }
}

const PasswordForgetLink = () => (
  <Box my={3}>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </Box>
)

export default PasswordForgetPage

const PasswordForgetForm = PasswordForgetFormBase

export { PasswordForgetForm, PasswordForgetLink }
