import React from 'react'
import { User } from '../Shared/common_defs'
import CollapsibleSection from '../Shared/CollapsibleSection'
import { Table } from '@workwhile/ui'
import { keyValueColumns } from 'components/Users/keyValueColumns'

interface Props {
  user: User
}

const UserLogUrls = ({ user }: Props) => {
  const data = React.useMemo(
    () => [
      {
        value: 'GCP user logs',
        ...{
          link: `https://console.cloud.google.com/logs/query;query=resource.type%3D%22gae_app%22%0Alog_name%3D%22projects%2Fworkwhile-247820%2Flogs%2Flib.observability.structured%22%0AjsonPayload.message.type%3D%22app_request_log%22%0AjsonPayload.message.user_id%3D${user.id}%0A;timeRange=PT3H;summaryFields=jsonPayload%252Fmessage%252Fstatus_code,jsonPayload%252Fmessage%252Fmethod,jsonPayload%252Fmessage%252Fpath:false:32:beginning:false?project=workwhile-247820&folder=true&organizationId=136924707921`,
        },
      },
      {
        value: 'Mobile app bugsnag logs',
        ...{
          link: `https://app.bugsnag.com/workwhile/workwhile/errors?filters[event.since]=all&filters[search]=${user.firebaseId}`,
        },
      },
      {
        value: 'Sentry error logs for user',
        ...{
          link: `https://workwhile.sentry.io/issues/?environment=production&query=user.id%3A${user.id}+issue.category%3Aerror&statsPeriod=7d`,
        },
      },
      {
        value: 'Mobile app Amplitude user lookup',
        ...{
          link: `https://app.amplitude.com/analytics/workwhile/project/241707/search/user_id%3D${user.firebaseId}`,
        },
      },
      {
        value: 'Business portal bugsnag logs',
        ...{
          link: `https://app.bugsnag.com/workwhile/employer/errors?filters[event.since]=all&filters[search]=${user.firebaseId}`,
        },
      },
      {
        value: 'Stripe API logs',
        ...{
          link: `https://dashboard.stripe.com/logs?object=${user.stripeAccountIdProd}&method[0]=post&method[1]=delete&direction[0]=self&direction[1]=connect_in&showIP=false`,
        },
      },
      {
        value: 'Checkr API logs',
        ...{
          link: `https://dashboard.checkr.com/logs/api?page=1&per_page=25&env=US&resource_id=${user.checkrId}`,
        },
      },
    ],
    []
  )

  return (
    <CollapsibleSection title="Log Urls" defaultOpen>
      <Table
        variant={'borderless'}
        data={data}
        columns={keyValueColumns.slice(1)}
      />
    </CollapsibleSection>
  )
}

export default UserLogUrls
