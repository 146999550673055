import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'

interface Props {
  companyId: number
  cancellationPolicies
}
const CustomCancellationPolicy = ({
  companyId,
  cancellationPolicies,
}: Props) => {
  return (
    <>
      {hasCustomPolicies(cancellationPolicies) ? (
        <>
          <BootstrapTable
            keyField="id"
            data={cancellationPolicies}
            columns={[
              {
                dataField: 'startsAt',
                text: 'Starts At',
                sort: true,
                formatter: (cell, row) => {
                  return cell.split('T')[0]
                },
              },
              {
                dataField: 'policyWindows',
                text: 'Policy Windows',
                formatter: (cell, row) => {
                  return (
                    <BootstrapTable
                      keyField="cutoffTimeHours"
                      data={cell}
                      columns={[
                        {
                          dataField: 'cutoffTimeHours',
                          text: 'Cutoff Time Hours',
                          sort: true,
                        },
                        {
                          dataField: 'chargePercentage',
                          text: 'Charge Percentage',
                          sort: true,
                        },
                      ]}
                    />
                  )
                },
              },
            ]}
          />
        </>
      ) : (
        'Customer does not have a custom policy.'
      )}

      <h5>
        We charge companies for 100% of their shift if they cancel within 24
        hours. Customers may negiate something else. To update their policy you
        need to know
        <li>
          <code>--cutoff-time</code>: How many hours before the shift should
          this new policy apply? Cannot be more than 24.
        </li>
        <li>
          <code>--charge-percentage</code>: How much should they be charged? 50
          for 50%
        </li>
        <li>
          <code>--start-date</code>: When should this policy start formatted as
          YYYY-MM-DD. Only shifts <i>created</i> after the policy start time
          will be affected.
        </li>
        <br />
        For e.g. if starting in the new year your company wants to only pay 50%
        if they cancel within 24 hours, and only 100% if they cancel within 12
        hours. This is just a template PLEASE UPDATE the hours, rate, and start
        date.
        <br />
        <code>
          @wobot company set-custom-cancellation-policy --company-id={companyId}{' '}
          --cutoff-time=12 --charge-percentage=50 --start-date 2023-01-01
        </code>
      </h5>
    </>
  )
}
export default CustomCancellationPolicy

export function hasCustomPolicies(cancellationPolicies) {
  return cancellationPolicies && cancellationPolicies.length > 0
}
