import React, { useEffect, useState } from 'react'
import graphql from '../../../graphql'
import WorkerList from './WorkerList'
import { getWaitlistCount } from '../shifts'
import Loading from '../../Shared/loading'
import AssignShift from './assign_shift'
import { WorkShift } from '../../Shared/common_defs'
import RefreshButton from '../../Shared/RefreshButton'
import { defs } from '../../Shared/defs'
import ShiftRosters from './ShiftRosters'

interface Props {
  shift: WorkShift
}

const Workers = ({ shift }: Props) => {
  const [showWorkerList, setShowWorkerList] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [fetchingWorkStats, setFetchingWorkStats] = useState(false)
  const [workStats, setWorkStats] = useState(null)

  // given a shift id, get top level stats from backend:
  // Needed, Cushioned, Scheduled, Started, Finished
  // Waitlist, rejected, removed
  const workStatsQuery = (shiftId) => {
    return `
            admin {
                shift (shiftId:${shiftId}) {
                    id, cancelledAt, workersNeeded, numCushionWorkersNeeded, numWorkersScheduled,
                    numWorkersStarted, numWorkersFinished(excludeEmployerCancelled:true), numWorkersRejected, numWorkersRemoved,
                    rosters {
                      id, isSpecificWorkerRequest, name,
                      workers {
                          id, name, profilePicUrl
                      }
                    },
                    rostersHoldExpiresAt,
                    listings {numWaitlist },
                }
            }`
  }

  useEffect(() => {
    // componentDidMount - use the info we already asked for in shift query
    // we want to avoid multiple queries on mount
    setWorkStats(shift)
  }, [])

  // fetchWorkStats used in actions in child components that require a refresh of the top level worker data
  const fetchWorkStats = (showLoading: boolean) => {
    if (showLoading) {
      setFetchingWorkStats(true)
    }
    graphql(workStatsQuery(shift.id))
      .then((response) => {
        const shiftData = response.data.data.admin.shift
        if (shiftData) {
          setWorkStats(shiftData)
        }
        setFetchingWorkStats(false)
      })
      .catch(() => {
        setFetchingWorkStats(false)
      })
  }

  const getShiftLeads = () => {
    if (!shift.leadShifts || shift.leadShifts.length === 0) {
      return null
    }

    return shift.leadShifts.map((leadShift, index) => (
      <div key={index} style={{ marginBottom: defs.marginL }}>
        <b>Shift Lead Shift {leadShift.id}</b>
        <WorkerList shift={leadShift} fetchWorkStats={fetchWorkStats} />
      </div>
    ))
  }

  if (fetchingWorkStats) {
    return <Loading type="button" />
  }

  if (!workStats) {
    return <p>Could not find info on workers for this shift.</p>
  }

  const cancelled = workStats.cancelledAt

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <div>
          <p>
            {shift.isTryout && (
              <span>
                <b>TRYOUT</b> |{' '}
              </span>
            )}
            {cancelled && (
              <span>
                <b>CANCELLED</b> |{' '}
              </span>
            )}
            <b>NEEDED:{workStats.workersNeeded}</b> |{' '}
            <b>CUSHIONED:{workStats.numCushionWorkersNeeded} </b> |{' '}
            <b>SCHEDULED:{workStats.numWorkersScheduled}</b> |{' '}
            <b>STARTED:{workStats.numWorkersStarted}</b> |{' '}
            <b>FINISHED: {workStats.numWorkersFinished}</b>
          </p>
          {workStats.rosters && workStats.rosters.length > 0 ? (
            <ShiftRosters
              shift={shift}
              rosters={workStats.rosters}
              rostersHoldExpiresAt={workStats.rostersHoldExpiresAt}
            />
          ) : null}
          <p>
            WAITLIST:{getWaitlistCount(workStats)}
            <br />
            REJECTED:{workStats.numWorkersRejected} | REMOVED:
            {workStats.numWorkersRemoved}
          </p>
        </div>
        <RefreshButton refreshFn={() => fetchWorkStats(true)} />
      </div>

      {getShiftLeads()}

      <button
        className="standard-button"
        onClick={() => setShowWorkerList(!showWorkerList)}
        type="button"
      >
        {showWorkerList ? 'Hide' : 'Show'} Workers
      </button>

      {showWorkerList && (
        <WorkerList shift={shift} fetchWorkStats={fetchWorkStats} />
      )}

      <button
        className="standard-button"
        onClick={() => setShowActions(!showActions)}
        type="button"
      >
        {showActions ? 'Hide' : 'Show'} Actions
      </button>

      <button
        className="standard-button"
        onClick={() =>
          window.open(`/shift_analytics?shiftId=${shift.id}`, '_blank')
        }
        type="button"
      >
        Show Shift Fill Analytics
      </button>

      {showActions && (
        <>
          <br />
          <br />
          <AssignShift shiftId={shift.id} callback={fetchWorkStats} />
        </>
      )}
    </div>
  )
}

export default Workers
