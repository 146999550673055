import { Listing, WorkShift } from 'api/typings'
import React from 'react'
import { parseISO } from 'date-fns'
import { Box, Text, Flex } from '@workwhile/ui'
import { format, toZonedTime } from 'date-fns-tz'

interface Props {
  shift: WorkShift
  listing: Listing
  children: React.ReactNode
}

export const UserJobRequestsShiftDetail = ({
  shift,
  listing,
  children,
}: Props) => {
  const location = listing?.location
  const timeZone =
    location?.address?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  // Correctly convert the ISO string to the local time of the shift
  const zonedStartsAt = toZonedTime(parseISO(shift.startsAt), timeZone)
  const zonedEndsAt = toZonedTime(parseISO(shift.endsAt), timeZone)

  // Format the time to include the correct timezone abbreviation
  const startsAt = format(zonedStartsAt, 'iii MMM do, yyyy hh:mma', {
    timeZone,
  })
  const endsAt = format(zonedEndsAt, 'iii MMM do, yyyy hh:mma', { timeZone })

  const startsAtTimeZone = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    timeZoneName: 'short',
    hour: 'numeric', // Necessary for some environments to include the timezone
  })
    .format(zonedStartsAt)
    .split(' ')[2]

  const endsAtTimeZone = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    timeZoneName: 'short',
    hour: 'numeric', // Necessary for some environments to include the timezone
  })
    .format(zonedEndsAt)
    .split(' ')[2]

  const formattedStartsAt = `${startsAt} ${startsAtTimeZone}`
  const formattedEndsAt = `${endsAt} ${endsAtTimeZone}`

  return (
    <Box>
      <Flex>
        <Text fontWeight="bold">shift_id</Text>
        <Text>: {shift.id}</Text>
      </Flex>
      <Text>
        Starts At: {formattedStartsAt} || Ends At: {formattedEndsAt} || Lunch
        Length: {shift.lunchLength} minutes
      </Text>
      <Text>
        Pay: {shift.payRate ? `$${shift.payRate}/hr` : `$${shift.payLumpSum}`}
        {shift.shiftBonuses &&
          shift.shiftBonuses.length > 0 &&
          ` || Bonus: $${shift.shiftBonuses[0].amount}`}
      </Text>
      {children}
    </Box>
  )
}
