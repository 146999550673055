import React from 'react'
import { Text } from '@workwhile/ui'

export const keyValueColumns = [
  {
    accessorKey: 'key',
    cell: ({ getValue }) => {
      return <Text color={'lightText'}>{getValue()}</Text>
    },
  },
  {
    accessorKey: 'value',
    cell: ({ getValue, row }) => {
      if (row.original.link) {
        return (
          <a target={'_blank'} rel={'noreferrer'} href={row.original.link}>
            {getValue()}
          </a>
        )
      }
      return <Text>{getValue()}</Text>
    },
  },
]
