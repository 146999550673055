import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getAuth, User, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { Loading } from '@workwhile/ui'

export const config = {
  apiKey: 'AIzaSyDkAPtzfcldOGgX8eB0oMKBhsAJ_KKoTAA',
  authDomain: 'workwhile-247820.firebaseapp.com',
  databaseURL: 'https://workwhile-247820.firebaseio.com',
  projectId: 'workwhile-247820',
  storageBucket: 'gs://workwhile-247820.appspot.com/',
  messagingSenderId: '280730972481',
  appId: '1:280730972481:web:b9ce8b41de42cb579d71c0',
}

const app = initializeApp(config)
export const auth = getAuth(app)

export interface AuthContextValueProps {
  user: User | null
  isAuthenticated: boolean
}

export const AuthContext = createContext<AuthContextValueProps>(null!)

interface Props {
  children: ReactNode
}

export const AuthProvider = (props: Props) => {
  const { children } = props
  const [initializing, setInitializing] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      setUser(user)
      if (initializing) {
        setInitializing(false)
      }
    })
  }, [])

  const values = useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
    }),
    [user]
  )

  if (initializing) {
    return <Loading />
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
