import { useQuery } from '@tanstack/react-query'
import { leftEarlyShifts } from './keys'
import { getLeftEarlyShiftsForReview } from 'api/review'

export function useLeftEarlyShifts() {
  return useQuery({
    queryKey: leftEarlyShifts,
    queryFn: getLeftEarlyShiftsForReview,
  })
}
