import React from 'react'
import { User } from 'api/typings'
import { WorkerAvailability } from 'api/user'
import { Box, Text } from '@workwhile/ui'
import { format } from 'date-fns'

const AvailabilityRow = (availability: WorkerAvailability, index: number) => {
  const formattedDate = format(
    new Date(availability.shift.start),
    'EEEE MMMM do'
  )
  return (
    <Box key={index} p={1} m={1}>
      <Text>{formattedDate}</Text>
      <Box>
        <Text fontSize={1} color="error">
          {availability?.reasons?.[0]}
        </Text>
      </Box>
    </Box>
  )
}

interface Props {
  worker: User
  availabilitiesForWorker: WorkerAvailability[]
}

export const WorkerUnavailableReasons = ({
  availabilitiesForWorker,
}: Props) => {
  return (
    <Box>
      <Box>
        {availabilitiesForWorker.map((availability, index) =>
          AvailabilityRow(availability, index)
        )}
      </Box>
    </Box>
  )
}
