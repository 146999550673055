// @ts-nocheck
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { defs } from '../Shared/defs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated/dist/react-select.esm'
import { useEffect } from 'react'
import { getRecentShiftDescriptions } from '../../actions/messaging_actions'
import { showErrorToast } from '../utils/util'

interface Props {
  showModal: boolean
  hideModal: () => void
  submitFiltersCallback: () => void
  searchParam?: string
  unread?: boolean
  automated?: boolean
  manual?: boolean
  setSearchInput?: (value: string) => void
  setUnreadInput?: (value: boolean) => void
  setAutomatedInput?: (value: boolean) => void
  setManualInput?: (value: boolean) => void
  value?: string
  selectedShift?: object
  handleFilterChange?: (selectedOption?: object) => void
}

const animatedComponents = makeAnimated()

const FilterMessagesModal = ({
  showModal,
  hideModal,
  searchParam,
  unread,
  automated,
  manual,
  setSearchInput,
  setUnreadInput,
  setAutomatedInput,
  setManualInput,
  submitFiltersCallback,
  selectedShift,
  handleFilterChange,
}: Props) => {
  const [
    requestingRecentShiftDescriptions,
    setRequestingRecentShiftDescriptions,
  ] = useState(false)
  const [recentShiftDescriptions, setRecentShiftDescriptions] = useState([])

  useEffect(() => {
    requestRecentShiftDescriptions()
  }, [])

  const requestRecentShiftDescriptions = () => {
    setRequestingRecentShiftDescriptions(true)
    getRecentShiftDescriptions()
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setRecentShiftDescriptions(response.data)
        }
        setRequestingRecentShiftDescriptions(false)
      })
      .catch((err) => {
        showErrorToast(err.message)
        setRequestingRecentShiftDescriptions(false)
      })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (submitFiltersCallback) {
      submitFiltersCallback()
      hideModal()
    }
  }

  return (
    <Modal
      style={{ marginTop: 50, paddingBottom: 100 }}
      show={showModal}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Messages</Modal.Title>
      </Modal.Header>
      <div style={{ padding: defs.marginXS, paddingBottom: 20 }}>
        <div style={{ marginBottom: defs.marginS }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <div>Filter</div>
            <div>
              <a
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() =>
                  alert(
                    'search query in the format ${search_type}: ${query_param} e.g. worker_id: 3948, worker_name: Michael Scott, sender_id: 964, shift_id:19135'
                  )
                }
              >
                help
              </a>
            </div>
          </div>
          <div style={{ flexDirection: 'row', flex: 1 }}>
            <input
              style={{ flex: 1, width: '100%' }}
              name="search"
              value={searchParam}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={
                'e.g. "worker_id:964, sender_id:4, worker_name: Alex, shift_id:19135"'
              }
            />
          </div>
        </div>
        <div
          style={{ flexDirection: 'row', flex: 1, marginBottom: defs.marginS }}
        >
          <span>Show unread only</span>
          <input
            className="input"
            type="checkbox"
            checked={unread}
            id="unread"
            onChange={() => setUnreadInput(!unread)}
          />
        </div>
        <div
          style={{ flexDirection: 'row', flex: 1, marginBottom: defs.marginS }}
        >
          <span>Show Manual only</span>
          <input
            className="input"
            type="checkbox"
            checked={manual}
            id="manual"
            onChange={() => setManualInput(!manual)}
          />
        </div>
        <div
          style={{ flexDirection: 'row', flex: 1, marginBottom: defs.marginS }}
        >
          <span>Show Automated only</span>
          <input
            className="input"
            type="checkbox"
            checked={automated}
            id="automated"
            onChange={() => setAutomatedInput(!automated)}
          />
        </div>
        <div className="alert alert-primary">
          Note: Once you reply to an automated msg, it will become a manual call
          list msg
        </div>
        <div style={{ marginBottom: defs.marginS, minHeight: 50 }}>
          <div>Shifts Messaged in the last 24 hrs</div>
          <Select
            isLoading={requestingRecentShiftDescriptions}
            isSearchable
            isClearable
            value={selectedShift}
            options={recentShiftDescriptions}
            components={animatedComponents}
            styles={customStyles}
            name="shift"
            placeholder="Filter By Shift"
            onChange={handleFilterChange}
          />
        </div>
        <button type="submit" className="ml-2" onClick={onSubmit}>
          Submit
        </button>
      </div>
    </Modal>
  )
}

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
  }),
}

export default FilterMessagesModal
