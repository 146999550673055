import api from 'api'
import { WorkShift } from 'api/typings'
import graphql from 'graphql'
import { path } from 'ramda'

export async function getLTAShift(shiftId: number) {
  const response = await graphql(`
      admin { 
        shift(shiftId: ${shiftId}) {
            id, startsAt, endsAt, lunchLength, supervisors { id, name, phoneNumber, userId, status},
            company {name, id},
            position {
                name, id, 
                tutorials {
                    name, id
                }
            },
            listings {
                id, tier, tierTransitions, freeze
                shifts (grouping: "all") {
                  id, workersNeeded, startsAt, endsAt
                  location {name, id, address {city, state, street, unit, zip, lat, long, timezone}}
                  work (status: "all") {
                      id, status
                  }
                  company { id }
                }
            }
            work (status: "all") {
                id, status
                worker { 
                    id, name,
                }
            }
        }
      }`)

  return path(['data', 'data', 'admin', 'shift'], response) as WorkShift
}

export async function updateLTAWorkStatus(
  workerId: string,
  shiftId: number,
  status: string
) {
  return api.put(
    `/admin/long_term_assignment/${workerId}/shift/${shiftId}/status/${status}`
  )
}

export async function updateLTAShiftLocation(
  shiftId: number,
  locationId: number
) {
  return api.put(
    `/admin/long_term_assignment/shift/${shiftId}/location/${locationId}`
  )
}
