import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { defs } from './defs'

interface Props {
  refreshFn: () => void
}

const RefreshButton = ({ refreshFn }: Props) => {
  return (
    <button
      style={{
        height: 30,
        backgroundColor: 'rgba(205, 205, 205, 0.2)',
        border: 'none',
        borderRadius: 4,
      }}
      onClick={refreshFn}
      type="button"
    >
      <MdRefresh />
    </button>
  )
}

export default RefreshButton
