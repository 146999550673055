import { Roster, WorkShift } from 'api/typings'
import { RequestedWorkerStatus } from 'components/Users/JobRequests/RequestedWorkerStatus'
import React, { useEffect } from 'react'
import { Flex, Box } from '@workwhile/ui'
import { useQueryClient } from '@tanstack/react-query'
import { workerAvailabilityKey } from 'queries/user/keys'
import { getWorkerAvailability } from 'api/user'

interface Props {
  shift: WorkShift
  roster: Roster
}

export const RosterWorkers = ({ roster, shift }: Props) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    // componentDidMount
    const shiftId = Number(shift?.id)
    const workerIds = roster.workers?.map((worker) => Number(worker.id))
    queryClient.prefetchQuery({
      queryKey: workerAvailabilityKey({
        shiftId,
        workerIds,
      }),
      queryFn: () =>
        getWorkerAvailability({
          shiftId,
          workerIds,
        }),
    })
  }, [])

  return (
    <>
      {roster.workers.map((worker) => (
        <Flex key={worker.id} flexWrap="wrap">
          {worker.name} (id: {worker.id})
          <Box ml={1}>
            <RequestedWorkerStatus worker={worker} shift={shift} />
          </Box>
          <br />
        </Flex>
      ))}
    </>
  )
}
