import React from 'react'
import { Box, Text, useAppTheme } from '@workwhile/ui'
import { toZonedTime, format as tzFormat } from 'date-fns-tz'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { WILL_OR_HAS_WORKED_STATUSES } from 'lib/work'
import { WorkShift } from 'api/typings'
import { ShiftLocationChange } from './ShiftLocationChange'

type Props = {
  shift: WorkShift
  currShiftId: number | null
  idx: number
  refetchLTA?: () => void
}

export const ShiftCollapsibleSection = ({
  shift,
  currShiftId,
  idx,
  refetchLTA,
}: Props) => {
  const { colors } = useAppTheme()

  const timeZone = shift?.location?.address?.timezone
  const startsAtZoned = toZonedTime(shift.startsAt, timeZone)
  const endsAtZoned = toZonedTime(shift.endsAt, timeZone)

  const startsAtFormatted = tzFormat(startsAtZoned, 'yyyy-MM-dd h:mma zzz', {
    timeZone,
  })
  const endsAtFormatted = tzFormat(endsAtZoned, 'yyyy-MM-dd h:mma zzz', {
    timeZone,
  })
  const isPastShift = new Date() > new Date(shift.startsAt)
  const isCurrentlyViewingShift = shift.id === currShiftId
  const numScheduledWorkers = shift?.work?.filter((work) =>
    WILL_OR_HAS_WORKED_STATUSES.includes(work?.status)
  )?.length

  return (
    <CollapsibleSection
      title={
        <Text
          fontWeight={isCurrentlyViewingShift ? 'bold' : 'normal'}
          fontSize={2}
          color={isPastShift ? 'neutral200' : null}
        >
          {idx + 1}. {isPastShift ? '(Past) ' : ''}
          {`Shift ${shift.id} | ${numScheduledWorkers} of ${shift.workersNeeded} workers | ${startsAtFormatted} - ${endsAtFormatted} | ${shift?.location?.name} (${shift?.location?.id}) | ${shift?.location?.address?.street}${shift?.location?.address?.unit ? ` ${shift?.location?.address?.unit}` : ''} ${shift?.location?.address?.city} ${shift?.location?.address.state} ${shift?.location?.address.zip}`}
        </Text>
      }
      contentStyles={{ marginBottom: 0, padding: 0 }}
    >
      <Box p={3} bg={colors.neutrals[100]}>
        <ShiftLocationChange shift={shift} successCallback={refetchLTA} />
      </Box>
    </CollapsibleSection>
  )
}
