import React from 'react'
import { Box, Tabs } from '@workwhile/ui'
import { LeftEarlyQueue } from './LeftEarlyQueue'
import { AdminReviewQueue } from './AdminReviewQueue'
import { useSearchParams } from 'react-router-dom'

export function ReviewPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <Box>
      <Tabs
        defaultValue={searchParams.get('tab') || 'left_early'}
        onValueChange={(value) => {
          setSearchParams(new URLSearchParams({ tab: value }))
        }}
        tabs={[
          {
            value: 'left_early',
            label: 'Left Early',
          },
          {
            value: 'admin_review',
            label: 'Admin Review',
          },
        ]}
      >
        <Tabs.Content value="left_early">
          <LeftEarlyQueue />
        </Tabs.Content>
        <Tabs.Content value="admin_review">
          <AdminReviewQueue />
        </Tabs.Content>
      </Tabs>
    </Box>
  )
}
