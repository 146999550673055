
export const reviewQuery = () => {
  return `
        admin { 
            leftEarlyWork {
                id, leftEarly,
                status, earnings,
                confirmedAt, travelMode,
                startedAt, completedAt,
                reasonEarlyClockout,
                worker { name, id }
                shift {
                        id, startsAt, endsAt, lunchLength, supervisors { id, name, phoneNumber, userId, status},
                        company {name, id},
                        position {name, id},
                        location {name, id, address {city, state, street, zip, lat, long, timezone}}
                    }
            }
        }`
}
