import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import moment from 'moment-timezone'
import graphql from '../../graphql'
import { getEventLogsColumns, eventLogQuery } from '../Shared/eventLogs'
import { User } from '../Shared/common_defs'
import { Box, Button, DatePicker, Field, Flex } from '@workwhile/ui'
import { addDays, format } from 'date-fns'

const _dayFormat = 'yyyy-MM-dd'

interface Props {
  user: User
}

interface DateRange {
  from: Date | undefined
  to?: Date | undefined
}

const UserEventLog = ({ user }: Props) => {
  const timezone =
    user && user.address ? user.address.timezone : moment.tz.guess(true)
  const eventLogsColumns = getEventLogsColumns(timezone)
  const [submittingEventLogRequest, setSubmittingEventLogRequest] =
    useState(false)
  const [eventLogs, setEventLogs] = useState(null)
  const [selectedDates, setSelectedDates] = useState<DateRange>({
    from: addDays(new Date(), -7),
    to: new Date(),
  })

  useEffect(() => {
    requestEventLog()
  }, [])

  const requestEventLog = (e?) => {
    if (e) {
      e.preventDefault()
    }
    if (!selectedDates.from || !selectedDates.to) {
      window.alert('Please enter both a start date and end date')
    }
    setEventLogs(null)
    setSubmittingEventLogRequest(true)
    graphql(
      eventLogQuery(
        user.id,
        format(selectedDates.from, _dayFormat),
        format(selectedDates.to, _dayFormat)
      )
    )
      .then((response) => {
        if (response.data.data.admin.eventLogs) {
          console.log(
            'response.data.data.admin.eventLogs: ',
            response.data.data.admin.eventLogs
          )
          setEventLogs(response.data.data.admin.eventLogs)
        }
        setSubmittingEventLogRequest(false)
      })
      .catch((error) => {
        setSubmittingEventLogRequest(false)
      })
  }

  return (
    <Box>
      <Field label={'See events between'}>
        <Flex>
          <DatePicker
            placeholder={'Select date ranges to see events'}
            mode={'range'}
            selected={selectedDates}
            onSelect={(range) => setSelectedDates(range as DateRange)}
          />
        </Flex>
      </Field>
      <Box mb={3}>
        <Button
          width={150}
          loading={submittingEventLogRequest}
          onClick={(e) => requestEventLog(e)}
        >
          View Logs
        </Button>
      </Box>
      {eventLogs && eventLogs.length > 0 ? (
        <BootstrapTable
          striped
          keyField="timestamp"
          data={eventLogs}
          columns={eventLogsColumns}
          rowStyle={{ fontSize: 11 }}
        />
      ) : (
        !submittingEventLogRequest && <p>No events found for this user.</p>
      )}
      <hr />
    </Box>
  )
}

export default UserEventLog
