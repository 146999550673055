import React, { useCallback, useEffect, useState } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
// import { Box, BoxProps } from 'ui'
import { Marker, MarkerProps } from '../Map/Marker'
import { GOOGLE_MAPS_API_KEY } from '../../../env'
import { MapCircle } from './MapCircle'

interface Props {
  name?: string
  zoom: number
  center?: {
    lat: number
    lng: number
  }
  markers?: MarkerProps[]
  circle?: {
    lat: number
    lng: number
    radius: number
    onCenterChange?: (center: { lat: number; lng: number }) => void
  }
}

export function Map(props: Props) {
  const { center, zoom, markers, circle } = props
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const ref = useCallback((node) => {
    if (node && typeof google !== 'undefined' && google.maps) {
      const map = new window.google.maps.Map(node, {
        zoom,
        center,
      })
      setMap(map)
    }
  }, [])

  useEffect(() => {
    if (map && center) {
      map.setCenter(center)
    }
  }, [center, map])

  useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom)
    }
  }, [zoom, map])

  return (
    <div style={{ height: '300px' }} ref={ref}>
      <Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['geocoding', 'places']}>
        {/* todo figure out the boxprops */}
        {map && markers
          ? markers.map((marker) => (
              <Marker
                key={`${marker.position.lat}-${marker.position.lng}`}
                map={map}
                {...marker}
              />
            ))
          : null}
        {map && circle ? (
          <MapCircle
            map={map}
            radius={circle.radius}
            center={{ lat: circle.lat, lng: circle.lng }}
            onCenterChange={circle.onCenterChange}
          />
        ) : null}
      </Wrapper>
    </div>
  )
}
