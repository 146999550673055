import React, { useState } from 'react'
import './Commands.css'
import api from '../../../api'
import { Heading, Button, Box } from '@workwhile/ui'

const Command = ({ command }) => {
  const [inputs, setInputs] = useState({})
  const [error, setError] = useState(null)
  const [output, setOutput] = useState(null)
  const [isExecuting, setIsExecuting] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs({ ...inputs, [name]: value })
  }

  const handleSubmit = () => {
    setIsExecuting(true)
    api
      .post('/admin/commands', { command: command.name, arguments: inputs })
      .then((response) => {
        alert('Success!')
        setOutput(response.data)
      })
      .catch((error) => {
        setOutput(null)
        setError(error.message)
      })
      .finally(() => {
        setIsExecuting(false)
      })
  }

  return (
    <div className="Command">
      <Heading level={3}>{command.name}</Heading>
      {command?.help ? (
        <Box style={{ whiteSpace: 'pre-wrap' }}>{command.help}</Box>
      ) : null}
      <div className="Command-inputs">
        {command?.args?.map((arg) => (
          <div key={arg.name} className="Command-input">
            <label htmlFor={arg.name}>{arg.label}</label>
            {arg.type === 'text' ? (
              <textarea
                name={arg.name}
                id={arg.name}
                required={arg.required}
                onChange={handleChange}
                rows={1}
              />
            ) : (
              <input
                type={arg.type}
                name={arg.name}
                id={arg.name}
                required={arg.required}
                onChange={handleChange}
              />
            )}
          </div>
        ))}
        {command?.options?.map((option) => (
          <div key={option.name} className="Command-input">
            <label htmlFor={option.name}>{option.label}</label>
            {option.type === 'text' ? (
              <textarea
                name={option.name}
                id={option.name}
                required={option.required}
                onChange={handleChange}
                rows={1}
              />
            ) : (
              <input
                type={option.type}
                name={option.name}
                id={option.name}
                required={option.required}
                onChange={handleChange}
              />
            )}
          </div>
        ))}
      </div>
      <Button loading={isExecuting} onClick={handleSubmit}>
        Execute
      </Button>
      {error && <p className="Command-error">{error}</p>}
      {output && <pre className="Command-output">{output}</pre>}
    </div>
  )
}

export default Command
