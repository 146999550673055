import {
  Box,
  Button,
  Checkbox,
  Flex,
  Loading,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import { WorkShift } from 'api/typings'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import UserCompanyWorkerProfile from 'components/Users/UserCompanyWorkerProfile'
import { UserPastStrike } from 'components/Users/UserPastStrike'
import { UserTutorials } from 'components/Users/UserTutorials'
import { showErrorToast } from 'components/utils/util'
import { useUpdateLTAWorkStatusMutation } from 'queries/lta/useUpdateLTAWorkStatusMutation'
import { useSearchUsers } from 'queries/user'
import React, { useState } from 'react'

interface Props {
  baseWorkerInfo: { id?: string; name?: string }
  status?: string
  shift: WorkShift
  refetchShift: () => void
}

export function WorkerInfo({
  baseWorkerInfo,
  shift,
  refetchShift,
  status,
}: Props) {
  const [expanded, setExpanded] = useState(false)
  const [hasViewed, setHasViewed] = useState(false)

  const { colors } = useAppTheme()

  const { data: workers, isFetching } = useSearchUsers({
    keyword: baseWorkerInfo?.id,
    enabled: !!baseWorkerInfo?.id && expanded,
  })

  const worker = workers?.[0]

  const { mutate: updateLTAWorkStatus, isPending: isUpdatingLTAWorkStatus } =
    useUpdateLTAWorkStatusMutation(baseWorkerInfo?.id, shift.id, {
      onSuccess: () => {
        refetchShift()
      },
      onError: (error) => {
        showErrorToast(error)
      },
    })

  return (
    <Box mb={2} pb={2}>
      <Flex flexDirection="row" flex={1} alignItems="center">
        <Box mr={3}>
          <Text>
            {baseWorkerInfo?.name} ({baseWorkerInfo?.id}) - {status}
          </Text>
        </Box>

        <Flex alignItems="center" marginLeft="auto">
          <Box mr={3}>
            <Button variant="text" onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Hide' : 'Show'} details
            </Button>
          </Box>
          <Checkbox
            value={hasViewed ? 'checked' : 'unchecked'}
            onChange={(checked) => {
              setHasViewed(checked)
            }}
          >
            <Text>Viewed</Text>
          </Checkbox>
        </Flex>
      </Flex>
      {expanded ? (
        <Box borderRadius="standard" bg={colors.neutrals[100]} p={3}>
          {isFetching ? <Loading /> : null}
          {worker ? (
            <>
              <Flex flexDirection="row" flex={1} alignItems="center">
                <Box mr={3}>
                  <Text>Phone: {worker?.phoneNumber}</Text>
                </Box>
                <Box mr={3}>
                  <Text>Rating: {worker?.rating?.toFixed(2)}</Text>
                </Box>
                <Box mr={3}>
                  <Text>Num Shifts: {worker?.userStats?.shiftCount}</Text>
                </Box>
              </Flex>
              <CollapsibleSection title="Remove from Assignment">
                <Box mr={3}>
                  <Text mb={2}>
                    {worker.name}({worker.id}) will be removed from all upcoming
                    shifts in the assignment. If they are currently working a
                    shift, the removal will be queued to be executed 1hr after
                    they have clocked out.
                  </Text>
                  <Text mb={2}>
                    They will receive a message that says: "⚠️ WorkWhile
                    schedule change alert. The following long term assignment at
                    COMPANY_NAME has been removed from your schedule:[shifts]".
                  </Text>
                  <Text>
                    You may wish to separately text the worker with additional
                    context.
                  </Text>
                  <Button
                    variant="text"
                    onClick={() => updateLTAWorkStatus('removed')}
                    loading={isUpdatingLTAWorkStatus}
                  >
                    <Text color={colors.error}>
                      Remove {worker.name} from assignment
                    </Text>
                  </Button>
                </Box>
              </CollapsibleSection>
              <CollapsibleSection title="Worker Tutorials">
                <UserTutorials user={worker} />
              </CollapsibleSection>
              <UserCompanyWorkerProfile user={worker} />
              <CollapsibleSection title="Strikes">
                {worker.allStrikes
                  ? worker.allStrikes.map((strike) => (
                      <UserPastStrike
                        key={strike.id}
                        user={worker}
                        strike={strike}
                      />
                    ))
                  : null}
              </CollapsibleSection>
            </>
          ) : !isFetching ? (
            <Text>Worker not found</Text>
          ) : null}
        </Box>
      ) : null}
      <hr />
    </Box>
  )
}
