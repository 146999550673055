import React, { useEffect, useState } from 'react'
import { User } from '../../Shared/common_defs'
import graphql from '../../../graphql'
import BootstrapTable from 'react-bootstrap-table-next'
import { Box, Loading, Text } from '@workwhile/ui'
import { UserJobRequestsShiftDetail } from './UserJobRequestsShiftDetail'
import { RequestedWorkerStatus } from './RequestedWorkerStatus'

interface Props {
  user: User
}

const userJobRequestsQuery = (user_id) => {
  return `
      admin { 
          user (userId:${user_id}) {
            listings2(listingsFilter:"requested") {
                cursor,
                items {
                    id,
                    company { id, name },
                    position { id, name }, 
                    location { 
                      id, address{ city, state, street, zip, lat, long, timezone } 
                    }
                    shifts {
                      id, startsAt, endsAt, lunchLength, payRate, payLumpSum
                      shiftBonuses { amount }
                    }
                }
            }
          }
      }`
}

const UserJobRequestsList = ({ user }: Props) => {
  const [submittingRequest, setSubmittingRequest] = useState(false)
  const [jobRequests, setJobRequests] = useState([])

  useEffect(() => {
    requestJobRequests()
  }, [])

  const requestJobRequests = (e?) => {
    if (e) {
      e.preventDefault()
    }
    setJobRequests([]) // clear the current job requests list
    setSubmittingRequest(true)
    graphql(userJobRequestsQuery(user.id))
      .then((response) => {
        const workResponse = response.data.data.admin.user.listings2.items
        if (workResponse) {
          setJobRequests(workResponse)
        }
        setSubmittingRequest(false)
      })
      .catch((error) => {
        setSubmittingRequest(false)
      })
  }

  const jobRequestColumns = [
    {
      dataField: 'id',
      text: 'Listing',
      sort: true,
      headerStyle: { width: '4em', fontSize: 12 },
      formatter: (cell, row) => {
        return (
          <>
            <p>
              <b>listing_id</b>:{cell}
            </p>
            <p>
              {row.company?.name}({row.company?.id}) ||
              {row.position?.name}({row.position?.id}) ||
              {row.location && row.location.address
                ? row.location.address.street +
                  ' ' +
                  row.location.address.city +
                  ' ' +
                  row.location.address.state +
                  ' ' +
                  row.location.address.zip +
                  `(${row.location?.id})`
                : 'No Address'}{' '}
            </p>
          </>
        )
      },
    },
    {
      dataField: 'shifts',
      text: 'Shifts',
      sort: true,
      headerStyle: { width: '6em', fontSize: 12 },
      formatter: (cell, row) => {
        return (
          <>
            {row?.shifts && row.shifts.length > 0
              ? row.shifts.map((shift) => (
                  <UserJobRequestsShiftDetail
                    key={shift.id}
                    shift={shift}
                    listing={row}
                  >
                    <RequestedWorkerStatus worker={user} shift={shift} />
                  </UserJobRequestsShiftDetail>
                ))
              : null}
          </>
        )
      },
    },
  ]

  if (submittingRequest) {
    return <Loading />
  }

  return (
    <Box>
      <Text fontStyle={'italic'}>
        NOTE: User can be requested for a job via Request Specific Worker OR
        because they're on a List that the shift is restricted to
      </Text>
      <Text my={2} fontStyle={'italic'}>
        NOTE: only shows up to 50 Job Requests for this user
      </Text>
      <Text fontStyle={'italic'} mb={3}>
        To add a worker to a shift, please use the "shift assign" command on the{' '}
        <a href="/commands">commands page</a>
      </Text>
      <div>
        {jobRequests && jobRequests.length > 0 ? (
          <>
            <BootstrapTable
              striped
              keyField="id"
              data={jobRequests}
              columns={jobRequestColumns}
              rowStyle={{ fontSize: 11 }}
            />
          </>
        ) : (
          <Text color={'lightText'}>
            User has not been requested for any shifts
          </Text>
        )}
      </div>
    </Box>
  )
}

export default UserJobRequestsList
