import React, { useState } from 'react'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import { ref, getDownloadURL, uploadBytes, getStorage } from 'firebase/storage'
import api from '../../api'
import { Heading } from '@workwhile/ui'

function TipFileUpload({ firebase }) {
  const [file, setFile] = useState(null)
  const [companyId, setCompanyId] = useState('')
  const [uploading, setUploading] = useState(false)
  const [downloadURL, setDownloadURL] = useState('')
  const [errors, setErrors] = useState([])
  const [paymentItems, setPaymentItems] = useState([])

  const handleFileChange = (e) => {
    let file = e.target.files[0]

    // Validate file type
    if (file.type !== 'text/csv') {
      alert('Invalid file type. Only CSV files are allowed.')
      setFile(null)
      return
    }

    setFile(file)
  }

  const handleCompanyIdChange = (e) => {
    setCompanyId(e.target.value)
  }

  const handleFileUploadAndProcess = async () => {
    // Validate the file is selected
    if (!file || !companyId) {
      return
    }
    setUploading(true)
    let localErrors = []
    let localPaymentIds = []

    // Parse and validate the CSV
    const parseResult = await new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          resolve(results)
        },
      })
    })

    const rows = parseResult.data

    // Validate column names
    const columns = Object.keys(rows[0])
    if (!columns.includes('phone_number') || !columns.includes('amount')) {
      localErrors.push(
        "Invalid column structure. The CSV must have the columns: 'phone_number' and 'amount', please check the names and make sure there is no extra space or typo."
      )
      setErrors(localErrors)
      setUploading(false)
      return
    }

    // CSV parsed, and validated.
    for (const row of rows) {
      const phone_number = row.phone_number
      const amount = row.amount
      try {
        const res = await api.post(
          `/admin/company/${companyId}/tips`,
          {
            phone_number: phone_number,
            amount: amount,
          },
          {
            validateStatus: false,
          }
        )
        if (res.status === 201) {
          localPaymentIds.push(res.data)
        } else {
          localErrors.push(
            `Failed to post tip for phone number ${phone_number}: ${res.data.message.general}`
          )
        }
      } catch (error) {
        console.error('API request error:', error)
      }
    }

    setPaymentItems(localPaymentIds.length > 0 ? localPaymentIds : [])
    setErrors(localErrors.length > 0 ? localErrors : [])

    // Upload the file to Firebase storage for record.
    const storage = getStorage()
    let filename = new Date()
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
      .replace(/\//g, '-')
    const uniqueFilename = uuidv4() + '-' + filename
    const fileRef = ref(storage, `public/${companyId}/${uniqueFilename}`)
    setUploading(true)
    // Make the file publicly readable and set metadata
    const metadata = {
      cacheControl: 'public, max-age=31536000',
      contentDisposition: `inline; filename="${filename}"`,
      customMetadata: {
        public: 'true',
      },
    }
    await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(fileRef)
    setDownloadURL(url)
    setUploading(false)
  }

  return (
    <div>
      <Heading level={3}>1. Enter Company ID</Heading>
      <input
        type="text"
        placeholder="Company ID"
        value={companyId}
        onChange={handleCompanyIdChange}
      />
      <br />
      <br />
      <br />
      <Heading level={3}>2. Upload Tip CSV</Heading>
      <input type="file" onChange={handleFileChange} />
      <br />
      <br />
      <br />
      <Heading level={3}>3. Upload and Process</Heading>
      <button onClick={handleFileUploadAndProcess} disabled={uploading}>
        {uploading ? 'Uploading and Processing...' : 'Upload and Process'}
      </button>
      {downloadURL && (
        <h5>
          File has been saved to cloud storage.{' '}
          <a href={downloadURL} target="_blank" rel="noreferrer">
            Click here to view the file or save the link.
          </a>
        </h5>
      )}
      <br />
      <br />
      {errors.length > 0 && (
        <div>
          <Heading level={3}>There were the following errors:</Heading>
          {errors.map((error, index) => (
            <h5 key={index}>{error}</h5>
          ))}
        </div>
      )}
      <br />
      <br />
      {paymentItems.length > 0 && (
        <div>
          <Heading level={3}>The following UserPayments were created:</Heading>
          {paymentItems.map((id) => (
            <h5>{id}</h5>
          ))}
        </div>
      )}
    </div>
  )
}

export default TipFileUpload
