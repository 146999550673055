import moment from 'moment-timezone'
import { User, WorkShift } from '../Shared/common_defs'

export const getShiftTimezone = (shift: WorkShift): string => {
  return shift?.location?.address?.timezone || moment.tz.guess(true)
}

export const dateCommaTimeFormat = 'ddd MMM Do YYYY, h:mma zz'

export const getUserTimezone = (user: User) =>
  user.address?.timezone || moment.tz.guess(true)

export const dateTimeSubmittedFormat = 'YYYY-MM-DD h:mma z'

export const DB_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const isShiftFull = (shift: WorkShift): boolean => {
  if (!shift) return false
  return (
    Number(shift.workersNeeded + shift.numCushionWorkersNeeded) <=
    Number(shift.numWorkersFilled)
  )
}

export const isShiftInProgress = (shift: WorkShift) => {
  if (!shift) return false
  return moment(shift.startsAt) < moment()
}
