import React, { useState } from 'react'
import api from '../../api'
import { defs } from '../Shared/defs'
import Loading from '../Shared/loading'
import { User } from '../Shared/common_defs'

interface Props {
  user: User
  refreshData: () => void
}

const UserPenaltyWaive = ({ user, refreshData }: Props) => {
  const [reason, setReason] = useState('')
  const [retainStrike, setRetainStrike] = useState(false)
  const [submittingWaivePenalty, setSubmittingWaivePenalty] = useState(false)

  if (user.status != 'blocked' && user.status !== 'suspended') {
    return null
  }

  const submitWaivePenalty = (user) => {
    if (!reason || reason === '') {
      window.alert('you must provide a reason')
      return
    }
    setSubmittingWaivePenalty(true)

    const r = window.confirm(
      `You are about to wave USER(${user.id}) ${user.status} penalty. Reason: ${reason}. RetainStrike: ${retainStrike ? 'true' : 'false'}. To execute this action, please press Ok.`
    )

    if (r == true) {
      api
        .post(`/admin/user/penalty_waive`, {
          user_id: user.id,
          reason: reason,
          retain_strike: retainStrike,
        })
        .then((response) => {
          alert(`Penalty waived for user(${user.id})`)
          setSubmittingWaivePenalty(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingWaivePenalty(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingWaivePenalty(false)
    }
  }

  return (
    <>
      <span style={{ color: defs.mediumBlack }}>Waive Penalty</span>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 5 }}>Reason:</p>
        <input
          className="input"
          name="reason"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          type="text"
          placeholder="Reason"
          style={{ fontSize: 12 }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
        <p style={{ color: 'blue', cursor: 'pointer' }}>
          <a
            onClick={() =>
              window.alert(
                'If false, strike from the penalty will be deleted. If true, strike from the penalty will be retained.'
              )
            }
            style={{ marginRight: 5 }}
          >
            Retain Strike:
          </a>
        </p>
        <button
          disabled={retainStrike}
          style={{
            backgroundColor: retainStrike ? '#90ee90' : defs.white,
            fontSize: 12,
            marginRight: 5,
            borderRadius: defs.borderRadiusM,
            borderWidth: 1,
            borderColor: defs.mediumBlack,
          }}
          onClick={() => setRetainStrike(true)}
        >
          {retainStrike && '✓ '}True
        </button>
        <button
          disabled={!retainStrike}
          style={{
            backgroundColor: retainStrike ? defs.white : '#90ee90',
            fontSize: 12,
            borderRadius: defs.borderRadiusM,
            borderWidth: 1,
            borderColor: defs.mediumBlack,
          }}
          onClick={() => setRetainStrike(false)}
        >
          {!retainStrike && '✓ '}False
        </button>
      </div>
      <br />
      {!submittingWaivePenalty && (
        <button
          onClick={() => submitWaivePenalty(user)}
          className="confirm-button"
        >
          Waive Penalty
        </button>
      )}
      {submittingWaivePenalty && <Loading type="button" />}
    </>
  )
}

export default UserPenaltyWaive
