import React, { useState } from 'react'
import { Box, Button, Field, Heading, Input, Text } from '@workwhile/ui'
import { useForm } from 'react-hook-form'
import { useLTAShift } from 'queries/lta/useLTAShift'
import { WorkerInfo } from './WorkerInfo'
import { WILL_OR_HAS_WORKED_STATUSES } from 'lib/work'
import { LTACommands } from './LTACommands'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { ShiftCollapsibleSection } from './LTAShiftCollapsibleSection'

export function LTAPage() {
  const { register, handleSubmit } = useForm()
  const [shiftId, setShiftId] = useState<number | null>(null)
  const [showScheduledWorkers, setShowScheduledWorkers] = useState(false)

  const onSubmit = (data) => {
    const newShiftId = data.shiftId
    setShiftId(newShiftId)
    if (shiftId === newShiftId) {
      refetch()
    }
  }

  const { data, isFetching, refetch } = useLTAShift(shiftId)
  const willOrHasWorkedWorkers = data?.work
    ?.filter((work) => WILL_OR_HAS_WORKED_STATUSES.includes(work?.status))
    ?.sort(
      (a, b) =>
        WILL_OR_HAS_WORKED_STATUSES.indexOf(a.status) -
        WILL_OR_HAS_WORKED_STATUSES.indexOf(b.status)
    )

  const getScheduledWorkersView = () => {
    if (!data?.work) {
      if (!shiftId) {
        return <Text>Search for a shift to see scheduled workers</Text>
      }
    }

    if (willOrHasWorkedWorkers?.length === 0) {
      return <Text>No workers scheduled</Text>
    }

    return (
      <>
        <Button
          variant="text"
          onClick={() => setShowScheduledWorkers(!showScheduledWorkers)}
        >
          {willOrHasWorkedWorkers?.length > 0
            ? showScheduledWorkers
              ? 'Hide Workers'
              : 'Show Workers'
            : null}
        </Button>
        {showScheduledWorkers
          ? willOrHasWorkedWorkers?.map((work) => (
              <WorkerInfo
                key={work?.worker?.id}
                baseWorkerInfo={work.worker}
                status={work.status}
                shift={data}
                refetchShift={refetch}
              />
            ))
          : null}
      </>
    )
  }

  return (
    <Box>
      <Heading>Long Term Assignment</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Shift ID">
          <Box flexDirection="row" flex={1}>
            <Box display="inline-flex" mr={2}>
              <Input placeholder="Shift ID" {...register('shiftId')} />
            </Box>
            <Button type="submit" loading={isFetching}>
              Search
            </Button>
          </Box>
        </Field>
      </form>

      <Heading level={2}>Assignment Details</Heading>
      {data ? (
        <Box>
          <Text flexDirection="row">{`${data?.position?.name} (${data?.position?.id})`}</Text>
          <Text flexDirection="row">{`${data?.company?.name} (${data?.company?.id})`}</Text>
          {data?.position?.tutorials?.length > 0 ? <hr /> : null}
          {data?.position?.tutorials?.map((tutorial) => (
            <Text
              key={tutorial.id}
            >{`Tutorial ${tutorial.id} | ${tutorial.name}`}</Text>
          ))}
          <hr />
          {data?.listings?.map((listing) => (
            <Box key={listing.id}>
              <Text>
                {`Listing ${listing.id} | tier: ${listing.tier} | freeze: ${listing.freeze}`}
              </Text>
              <Heading
                level={4}
              >{`${listing?.shifts?.length} shifts in assignment`}</Heading>
              <ol>
                {listing?.shifts?.map((shift, idx) => {
                  return (
                    <ShiftCollapsibleSection
                      key={shift.id}
                      shift={shift}
                      currShiftId={shiftId}
                      idx={idx}
                      refetchLTA={refetch}
                    />
                  )
                })}
              </ol>
            </Box>
          ))}
        </Box>
      ) : (
        <Text>Search for a shift to see details</Text>
      )}

      <Heading level={2}>
        {willOrHasWorkedWorkers && willOrHasWorkedWorkers?.length > 0
          ? `${willOrHasWorkedWorkers?.length} `
          : ''}
        Scheduled Workers for shift {shiftId}
      </Heading>
      {getScheduledWorkersView()}

      <Heading level={2}>Actions</Heading>
      <CollapsibleSection title="LTA Commands">
        <LTACommands />
      </CollapsibleSection>
    </Box>
  )
}
