// @ts-nocheck
import React, { useState, ChangeEvent, FormEvent } from 'react'
import { Heading } from '@workwhile/ui'
import api from '../../api'

const FileUpload: React.FC<{
  label: string
  url: string
  hasDryRun: boolean
  description: string
  downloadFilename: string // Add a new prop for specifying the download filename
}> = ({ label, url, hasDryRun, description, downloadFilename }) => {
  const [file, setFile] = useState<File | null>(null)
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isDryRun, setIsDryRun] = useState<boolean>(true)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
      setMessage('')
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!file) {
      setMessage('Please select a file before uploading.')
      return
    }

    const formData = new FormData()
    formData.append('crossmark_shift_data', file)

    setLoading(true)

    try {
      const response = await api.post(url + `?dry_run=${isDryRun}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/zip',
        },
        responseType: 'blob',
      })

      const responseUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = responseUrl
      link.setAttribute('download', downloadFilename) // Use the specified download filename
      document.body.appendChild(link)
      link.click()

      setMessage('File uploaded and downloaded successfully.')
    } catch (error) {
      setMessage(
        'Error uploading file: ' +
          (error.response?.data?.message || error.message)
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
        <Heading level={2}>{label}</Heading>
        <p style={{ fontSize: '18px', whiteSpace: 'pre-line' }}>
          {description}
        </p>
        <br />
        <br />
        {hasDryRun && (
          <label>
            Dry Run:
            <input
              type="checkbox"
              checked={isDryRun}
              onChange={() => setIsDryRun(!isDryRun)}
              style={{ marginLeft: '10px' }}
            />
          </label>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <button
              type="submit"
              disabled={loading}
              style={{ padding: '10px 20px', cursor: 'pointer' }}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </form>
        {message && (
          <div style={{ marginTop: '20px', color: 'red' }}>{message}</div>
        )}
      </div>
    </div>
  )
}

const Spreadsheets: React.FC = () => {
  return (
    <div>
      <FileUpload
        label="Create Crossmark Shifts"
        url="admin/sheets/create_crossmark_shifts"
        hasDryRun={true}
        description={`
                Upload the Crossmark formatted spreadsheet to create shifts, when Dry Run is checked, no shifts will actually be created.
                After uploading, your browser will automatically download a zip file containing: 
                1. a CSV of the Crossmark shifts which were created (though not actually created if Dry Run is checked) and 
                2. a CSV which lists the updates to existing Crossmark shifts that need to be made on our platform
            `}
        downloadFilename="crossmark_shifts.zip"
      />
      <FileUpload
        label="Collect Crossmark Worker Names"
        url="admin/sheets/collect_crossmark_worker_names"
        hasDryRun={false}
        description="Upload a Crossmark formatted spreadsheet the spreadsheet to collect worker names for WorkWhile assigned tickets"
        downloadFilename="crossmark_sheet_with_worker_names.xlsx"
      />
    </div>
  )
}

export default Spreadsheets
