import React from 'react'

import api from '../../api'
import { Heading } from '@workwhile/ui'

const INITIAL_STATE = {
  listingId: null,
  amount: null,
  chargeToCompany: false,
  minNumShifts: 1,
}

class AddListingBonus extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { listingId, amount, chargeToCompany, minNumShifts } = this.state

    const r = window.confirm(
      `You are about to add a $${amount} Bonus to LISTING ${listingId}. Charge to company = ${chargeToCompany}. MinNumShifts = ${minNumShifts}. To execute this action, please press Ok.`
    )
    if (r == true) {
      api
        .post(`/admin/listing/bonus`, {
          listing_id: listingId,
          charge_to_company: chargeToCompany,
          amount,
          min_num_shifts: minNumShifts,
        })
        .then((response) => {
          console.log(response)
          alert('Bonus ADDED to LISTING')
          this.setState(INITIAL_STATE)
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
        })
    } else {
      alert('Action Cancelled!')
      this.setState({ submitting: false })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggleCheckbox(key) {
    this.setState({
      chargeToCompany: !this.state.chargeToCompany,
    })
  }

  render() {
    const { listingId, amount, chargeToCompany, minNumShifts, error } =
      this.state

    // const isInvalid = listingId === '' || amount === '';
    const isInvalid = true // TODO: switch this line for the line above to enable the submit button
    return (
      <div>
        <Heading>Add Bonus to LISTING</Heading>
        <div className="alert alert-warning">
          <strong>PSA!</strong> Listing bonus has been temporarily disabled
          until we have better edge case handling
        </div>
        <form onSubmit={this.onSubmit}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Listing ID</label>
            <input
              disabled
              className="input"
              name="listingId"
              value={listingId}
              onChange={this.onChange}
              type="text"
              placeholder="Listing Id"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Amount</label>
            <input
              disabled
              className="input"
              name="amount"
              value={amount}
              onChange={this.onChange}
              type="text"
              placeholder="Amount"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="charge-to-company">Charge to company</label>
            <input
              disabled
              className="input"
              type="checkbox"
              checked={chargeToCompany}
              id="charge-to-company"
              onChange={() => this.toggleCheckbox(chargeToCompany)}
            />
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="minNumShifts">
              Minimum number of shifts a worker has to complete to be paid the
              bonus
            </label>
            <input
              disabled
              className="input"
              name="minNumShifts"
              value={minNumShifts}
              onChange={this.onChange}
              type="text"
              placeholder="Min Num Shifts, default is 1"
              id={'minNumShifts'}
            />
          </div>
          <button
            disabled={isInvalid}
            type="submit"
            style={{ display: 'inline-block', width: '100%' }}
          >
            ADD
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    )
  }
}

export default AddListingBonus
