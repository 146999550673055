import React, { useState } from 'react'
import api from '../../../api'
import Loading from '../../Shared/loading'
import { Listing } from '../../Shared/common_defs'

interface Props {
  listing: Listing
  fetchListings: () => void
}

const ListingNotify = ({ listing, fetchListings }: Props) => {
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    const r = window.confirm(
      `You are about to notify all users who qualify for tier ${listing.tier.toUpperCase()} about listing ${listing.id}. To execute this action, please press Ok.`
    )
    if (r == true) {
      api
        .post(`/admin/listing/${listing.id}/notify`)
        .then((response) => {
          fetchListings()
          alert(`Enqueued notification for listing ${listing.id}`)
          setSubmitting(true)
        })
        .catch((error) => {
          setSubmitting(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmitting(false)
    }
  }

  return (
    <div>
      <hr />
      <b>Notify Listing</b>
      <p>
        Immediately notify all users who{' '}
        <a
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() =>
            window.alert(
              '*Qualify: Users who qualify for this tier who are not already engaged with a shift in this listing, blocked or have schedule conflicts. Users who are on the waitlist for this listing WILL BE NOTIFIED.'
            )
          }
        >
          qualify*
        </a>
        for tier <b>{listing.tier}</b> about listing <b>{listing.id}</b>.
      </p>
      {!submitting && (
        <button className="confirm-button" type="submit" onClick={onSubmit}>
          Notify listing {listing.id}
        </button>
      )}
      {submitting && <Loading type="button" />}
      <hr />
    </div>
  )
}

export default ListingNotify
