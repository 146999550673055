import React from 'react'
import { defs } from '../Shared/defs'

interface Props {
  src?: string
  name?: string
  unreadCount: number
  companyLogoUrl?: string
}

const ConversationAvatar = ({
  src,
  name,
  unreadCount,
  companyLogoUrl,
}: Props) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={src}
        style={{
          width: 40,
          height: 40,
          borderRadius: 40,
          margin: defs.marginS,
        }}
      />
      {unreadCount > 0 && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'red',
            width: 22,
            height: 22,
            borderRadius: 22,
            right: 5,
            top: 5,
          }}
        >
          <span style={{ fontSize: 10, color: defs.white }}>{unreadCount}</span>
        </div>
      )}
      {companyLogoUrl && (
        <div style={{ position: 'absolute', bottom: 5, right: 5 }}>
          <img
            src={companyLogoUrl}
            style={{
              width: 22,
              height: 22,
              borderRadius: 4,
              objectFit: 'contain',
              backgroundColor: defs.offWhite,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ConversationAvatar
