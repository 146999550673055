import { WorkerAvailabilityRequest } from 'api/user'

export const userKey = ['user']
export const searchUserKey = (keyword: string) => [...userKey, keyword]
export const workerAvailabilityKey = ({
  shiftId,
  workerIds,
}: WorkerAvailabilityRequest) => [
  ...userKey,
  'workerAvailability',
  shiftId,
  workerIds?.join('|'),
]
