import React, { useEffect, useState } from 'react'
import { sharedStyles } from '../Shared/sharedStyles'
import { MdExpandMore, MdExpandLess } from 'react-icons/md'
import { Heading } from '@workwhile/ui'

interface Props {
  title: string | React.ReactNode
  children: React.ReactNode
  containerStyles?: object
  defaultOpen?: boolean
  titleStyles?: object
  contentStyles?: object
}

const CollapsibleSection = ({
  title,
  children,
  containerStyles,
  defaultOpen,
  titleStyles,
  contentStyles,
}: Props) => {
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (defaultOpen) {
      setShowAll(true)
    }
  }, [])

  return (
    <div
      style={{
        ...sharedStyles.collapsibleSectionContainer,
        ...containerStyles,
      }}
    >
      <div style={{ ...sharedStyles.collapsibleSection, ...contentStyles }}>
        <div
          style={{ ...sharedStyles.collapsibleSectionTitleRow, ...titleStyles }}
          onClick={() => setShowAll(!showAll)}
        >
          <Heading level={4} my={0}>
            {title}
          </Heading>
          <div style={sharedStyles.collapsibleSectionTitleRowIcon}>
            {showAll ? <MdExpandLess /> : <MdExpandMore />}
          </div>
        </div>
        {showAll && children}
      </div>
    </div>
  )
}

export default CollapsibleSection
