import React, { useEffect, useState } from 'react'
import { fetchUpcomingShiftsForPosition } from '../../actions/company_actions'
import BootstrapTable from 'react-bootstrap-table-next'
import { Map } from '../Shared/Map'

const METERS_TO_FEET = 3.28084
interface Props {
  positionData
}
const LocationEditor = ({ positionData }: Props) => {
  const [locations, setLocations] = useState([])

  useEffect(() => {
    const responseCallback = (response) => {
      const shifts = response.data.data.admin.companies[0].shifts2.items
      formatLocations(shifts)
    }
    const errorCallback = (error) => {
      console.log(error)
    }
    fetchUpcomingShiftsForPosition(
      positionData.id,
      positionData.company.id,
      responseCallback,
      errorCallback
    )
  }, [positionData])

  function formatLocations(shifts) {
    if (shifts.length === 0) {
      return
    }
    const uniqueLocations = Object.values(
      shifts.reduce((acc, cur) => {
        if (!acc[cur.location.id]) {
          acc[cur.location.id] = cur.location
        }
        return acc
      }, {})
    )
    setLocations(uniqueLocations)
  }

  const addressFormatter = (cell, row, type) => {
    if (!cell) {
      return <div>None</div>
    }
    if (type === 'street') {
      return (
        <>
          {cell.street} {cell.unit} <br />
          {cell.city}, {cell.state} {cell.zip}
        </>
      )
    } else if (type === 'latlng') {
      return (
        <>
          {cell.lat}, {cell.long} <br />
          {cell.radius * METERS_TO_FEET} feet radius
        </>
      )
    }
  }

  const mapFormatter = (cell, row, lat, long, radius) => {
    // TODO(anjali): Improve the radius to zoom function.
    if (!lat || !long) {
      return <div>No lat/lng specified</div>
    }
    return (
      <>
        <Map
          zoom={radius > 300 ? 15 : 17}
          center={{ lat: lat, lng: long }}
          markers={[
            {
              position: { lat: lat, lng: long },
              title: 'New Location',
            },
          ]}
          circle={{
            radius: radius,
            lat: Number(lat),
            lng: Number(long),
          }}
        />
      </>
    )
  }

  const locationColumns = [
    {
      dataField: 'id',
      text: 'Location ID ',
    },
    {
      dataField: 'name',
      text: 'Location name ',
    },
    {
      dataField: 'address',
      text: 'Address',
      formatter: (cell, row) => addressFormatter(cell, row, 'street'),
    },
    {
      dataField: 'address',
      text: 'Lat, Long',
      formatter: (cell, row) => addressFormatter(cell, row, 'latlng'),
    },
    {
      dataField: 'arrivalInstructions',
      text: 'Arrival Instructions',
    },
    {
      dataField: 'address',
      text: 'Work Map',
      formatter: (cell, row) =>
        mapFormatter(cell, row, cell.lat, cell.long, cell.radius),
    },
  ]

  const clockInColumn = {
    dataField: 'address',
    text: 'Clock-In Map',
    formatter: (cell, row) =>
      mapFormatter(
        cell,
        row,
        row.clockInLat,
        row.clockInLong,
        row.clockInRadius
      ),
  }
  function hasClockInCoords() {
    return (
      locations && locations?.length > 0 && locations[0].clockInLat !== null
    )
  }
  return (
    <>
      {hasClockInCoords() ? null : (
        <h5>The clock in location is the same as shift location. </h5>
      )}
      <br />
      If the maps aren't loading, try collapsing and expanding the dropdown.
      <br />
      To make changes you can log into Business Portal.
      <BootstrapTable
        keyField="id"
        data={locations}
        columns={
          hasClockInCoords()
            ? [...locationColumns, clockInColumn]
            : locationColumns
        }
        striped
        rowStyle={{ fontSize: 14 }}
      />
      <br />
    </>
  )
}
export default LocationEditor
