import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { Work } from 'api/typings'

export async function getLeftEarlyShiftsForReview() {
  const response = await graphql(`
    admin { 
      leftEarlyWork {
        id, leftEarly,
        status, earnings,
        confirmedAt, travelMode,
        startedAt, completedAt,
        reasonEarlyClockout,
        worker { name, id }
        shift {
          id, startsAt, endsAt, lunchLength, supervisors { id, name, phoneNumber, userId, status},
          company {name, id},
          position {name, id},
          location {name, id, address {city, state, street, zip, lat, long, timezone}}
        }
      }
    }`)

  return path(['data', 'data', 'admin', 'leftEarlyWork'], response) as Work[]
}

export async function getAdminReviewShiftsForReview() {
  const response = await graphql(`
    admin { 
        work (status: "admin_review") {
            id, status, leftEarly, reasonEarlyClockout, supervisorReasonEarlyClockOut, supervisorMarkedNoShow, isCancelledForIllness
            confirmedAt, travelMode,
            supervisorMarkedNoShow,
            noShowMarkedByShiftLeadId,
            startedAt, completedAt,
            worker {name, id, phoneNumber}
            adminReviewDetail
            associatedOffense{
              userExplanation
            }
            shift {
                id, startsAt, endsAt, lunchLength, supervisors { id, name, phoneNumber, userId, status},
                company {name, id},
                position {name, id},
                location {name, id, address {city, state, street, zip, lat, long, timezone}}
            }
            adminReview {
              workerClaimsTheyWorked, workerSubmittedDocsForBail
            }
            trackingIssueReason
        }
    }`)

  return path(['data', 'data', 'admin', 'work'], response) as Work[]
}
