import React from 'react'
import './not_found.css'

export function NotFound() {
  return (
    <div id="404" className="not_found">
      <h1>Page Not Found</h1>
      <section className="error-container">
        <span>
          <span>4</span>
        </span>
        <span>0</span>
        <span>
          <span>4</span>
        </span>
      </section>
    </div>
  )
}
