export const size = {
  mobileM: 375 /* Try to avoid using this size. Only for unavoidable edge cases */,
  mobileL: 425,
  tablet: 850,
  laptop: 1024,
}

export const device = {
  // devices with width < X
  smallerThanMobileM: `(max-width: ${size.mobileM}px)` /* Try to avoid using this size. Only for unavoidable edge cases */,
  smallerThanMobileL: `(max-width: ${size.mobileL}px)`,
  smallerThanTablet: `(max-width: ${size.tablet}px)`,
  smallerThanLaptop: `(max-width: ${size.laptop}px)`,

  // devices with width >= X
  biggerThanMobileM: `(min-width: ${size.mobileM}px)` /* Try to avoid using this size. Only for unavoidable edge cases */,
  biggerThanMobileL: `(min-width: ${size.mobileL}px)`,
  biggerThanTablet: `(min-width: ${size.tablet}px)`,
  biggerThanLaptop: `(min-width: ${size.laptop}px)`,
}
