import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { API_URL } from './constants/constants'

const api = axios.create({
  baseURL: API_URL,
})
export default api

// this method ensures that the auth token is always up-to-date
export async function getAuthToken(forceRefresh = false) {
  return new Promise((resolve) => {
    const unsubscribe = getAuth().onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(forceRefresh)
        resolve(token)
      } else {
        resolve(null)
      }
      unsubscribe()
    })
  })
}

api.interceptors.request.use(async (config) => {
  const idToken = await getAuthToken()
  if (idToken) {
    config.headers.Authorization = idToken
  }

  return config
})

api.defaults.headers.common['Content-Type'] = 'application/json'
api.defaults.headers.common['Accept'] = 'application/json'

/* show toast for any api error
 * most errors will come in this form (normalized to match marshmallow):
 * {
 *   "message": {
 *      'long': ['longitude must be number.'], 'general': ['you can not edit shift after offers are sent']
 *   }
 * }
 * it is still possible that some errors will come as error.message = string. e.g. AssertionError will throw a 500
 */
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error) {
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.message
      ) {
        alert(`${error}`)
      } else {
        displayErrors(error.response.data.message)
      }
      return Promise.reject(error)
    }
  }
)

const displayErrors = (msg) => {
  if (msg instanceof Object) {
    const keys = Object.keys(msg)
    for (let i = 0; i < keys.length; i += 1) {
      // for each message:
      const key = keys[i]
      if (key === 'general') {
        // if the error is general print it out.
        const arr = msg[key]
        let printout = 'Error: '
        arr.forEach((val) => {
          printout += val + '. '
        })
        alert(printout)
      } else {
        // else, the message is not "general" and requires context, give it context.
        const errorToShow = `${key}: ${msg[key][0]}`
        alert(errorToShow)
      }
    }
  } else if (typeof msg === 'string') {
    alert(msg)
  } else {
    alert(
      'Something went wrong. ' +
        'Please check your internet connection. ' +
        'If you require assistance, please contact us.'
    )
  }
}
