import React from 'react'
import { User } from '../Shared/common_defs'
import CollapsibleSection from '../Shared/CollapsibleSection'
import UserWorkList from './UserWorkList'

interface Props {
  user: User
}

const UserWork = ({ user }: Props) => {
  return (
    <CollapsibleSection title="Work List">
      <UserWorkList user={user} />
    </CollapsibleSection>
  )
}

export default UserWork
