import React from 'react'
import { Heading } from '@workwhile/ui'
import { PasswordForgetForm } from '../PasswordForget'
import PasswordChangeForm from '../PasswordChange'
import { useAuthContext } from 'components/auth'

export default function AccountPage() {
  const { user } = useAuthContext()
  return (
    <div>
      <Heading>Account: {user?.email}</Heading>
      <PasswordForgetForm />
      <PasswordChangeForm />
    </div>
  )
}
