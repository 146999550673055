import React from 'react'
import { Box, Button, Field, Flex, Select } from '@workwhile/ui'

interface Props {
  label: string
  name: string
  value: string
  options: Array<SelectOption>
  handleChange: (e: any) => void
  isLoading: boolean
  submitStatusChange: () => void
  buttonText?: string
  buttonType?: string
}

interface SelectOption {
  label: string
  value: string
}

const StatusSetter = ({
  label,
  name,
  value,
  options,
  handleChange,
  isLoading,
  submitStatusChange,
  buttonText,
  buttonType,
}: Props) => {
  return (
    <Field label={label}>
      <Flex>
        <Box flex={1} mr={2}>
          <Select
            name={name}
            value={options.find((option) => option.value === value)}
            onChange={(option) => {
              const { value } = option as { value: string }
              handleChange(value)
            }}
            options={options}
          />
        </Box>
        <Button
          type={buttonType}
          loading={isLoading}
          onClick={() => submitStatusChange()}
        >
          {buttonText || 'Set Status'}
        </Button>
      </Flex>
    </Field>
  )
}

export default StatusSetter
