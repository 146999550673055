import React, { useState } from 'react'
import { WorkerRequirement } from '../Shared/common_defs'

interface Props {
  about: string
  requirements: Array<WorkerRequirement>
  clockInRequirements: Array<string>
}

const PositionDetail = ({
  about,
  requirements,
  clockInRequirements,
}: Props) => {
  const [showDetails, setShowDetails] = useState(false)
  return (
    <div>
      <button onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'Show Details'}
      </button>
      {showDetails && (
        <div>
          <b>About</b>
          <p>{about}</p>
          {requirements && (
            <>
              <b>Requirements</b>
              {requirements.map((req) => (
                <p>
                  {req.name}
                  {req.credential !== 'none' ? '[HARD_REQ]' : ''}
                </p>
              ))}
            </>
          )}
          {clockInRequirements && clockInRequirements.length > 0 && (
            <>
              <b>Clock In Requirements</b>
              {clockInRequirements.map((req) => (
                <p>{req}</p>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default PositionDetail
