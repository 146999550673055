import React, { useState } from 'react'
import { Box, Button, Flex, Input } from '@workwhile/ui'
import { SearchIcon } from 'lucide-react'
import { useNavigate, useParams } from 'react-router'
import { useUpdateEffect } from 'ahooks'

export type SearchParams = {
  keyword?: string
}

export function SearchBox({ isLoading }: { isLoading: boolean }) {
  const navigate = useNavigate()
  const { keyword }: SearchParams = useParams<SearchParams>()
  const [searchTerm, setSearchTerm] = useState(keyword || '')

  useUpdateEffect(() => {
    setSearchTerm(keyword || '')
  }, [keyword])

  const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    navigate(`/users/${searchTerm}`)
  }

  return (
    <Flex
      as={'form'}
      onSubmit={onSearchSubmit}
      p={3}
      px={4}
      backgroundColor={'offWhite'}
    >
      <Box width={400} mr={2}>
        <Input
          block
          disabled={isLoading}
          icon={<SearchIcon size={18} />}
          name="search_term"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          type="text"
          placeholder="Search by name, id, firebase_id, email or phone"
        />
      </Box>
      <Button width={150} type="submit" disabled={isLoading}>
        Search
      </Button>
    </Flex>
  )
}
