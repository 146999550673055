import { useQuery } from '@tanstack/react-query'
import { getLTAShift } from 'api/lta'
import { ltaShiftKey } from 'queries/review/keys'

export function useLTAShift(shiftId: number | null) {
  return useQuery({
    queryKey: ltaShiftKey(shiftId),
    queryFn: () => getLTAShift(shiftId),
    enabled: !!shiftId, // This ensures the query only runs when shiftId is not null
    staleTime: 0,
  })
}
