import React from 'react'
import api from '../../../api'

const INITIAL_STATE = {
  user_ids: null,
}

class AssignShift extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { user_ids } = this.state
    const { shiftId, callback } = this.props

    if (!user_ids) {
      alert('Please enter at least one user id')
      return
    }

    const r = window.confirm(
      `You are about to assign SHIFT ${shiftId} to USER(s) ${user_ids}. To execute this action, please press Ok.`
    )
    if (r == true) {
      api
        .put(`/admin/shifts/assign`, {
          user_ids: user_ids,
          shift_id: shiftId,
        })
        .then((response) => {
          console.log(response)
          if (callback) callback()
          alert('Shift Assigned')
          this.setState({
            user_ids: '',
          })
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
        })
    } else {
      alert('Action Cancelled!')
      this.setState({ submitting: false })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { user_ids, error } = this.state
    const { shiftId } = this.props

    const isInvalid = user_ids === '' || shiftId === ''
    return (
      <div>
        <b>Assign shift</b>
        <form onSubmit={this.onSubmit}>
          <input
            className="input"
            name="user_ids"
            value={user_ids}
            onChange={this.onChange}
            type="text"
            placeholder="User IDs (csv, no spaces)"
          />
          <button disabled={isInvalid} type="submit" className="confirm-button">
            Send
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    )
  }
}

export default AssignShift
