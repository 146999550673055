import React, { useMemo, useState } from 'react'
import { Box, Button, Heading, Text, useAppTheme } from '@workwhile/ui'
import Select from 'react-select'
import { WorkShift } from 'api/typings'
import { useCompanyLocations } from 'queries/company/useCompanyLocations'
import { useUpdateLTAShiftLocationMutation } from 'queries/lta/useUpdateLTAShiftLocationMutation'

interface Props {
  shift: WorkShift
  successCallback?: () => void
}

export const ShiftLocationChange = ({ shift, successCallback }: Props) => {
  const { colors } = useAppTheme()
  const { data: locations, isLoading: isLoadingLocations } =
    useCompanyLocations(Number(shift?.company?.id))

  const { mutate: updateShiftLocation, isPending: submittingShiftLocation } =
    useUpdateLTAShiftLocationMutation(Number(shift?.id || 0), {
      onSuccess: () => {
        setNewLocationId(null)
        alert(`Shift ${shift?.id} location updated`)
        if (successCallback) {
          successCallback()
        }
      },
    })

  const [newLocationId, setNewLocationId] = useState<string | null>(null)

  const locationOptions = useMemo(() => {
    return locations?.map((location) => ({
      value: location.id,
      label: `${location.name}(${location.id}) - ${location.address.street}${location.address.unit ? ` ${location.address.unit}` : ''} ${location.address.city} ${location.address.state} ${location.address.zip}`,
    }))
  }, [locations])

  return (
    <>
      <Heading level={4}>
        Change Shift {shift?.id} Location (NOTE: only use this feature for
        travelling shifts)
      </Heading>
      <Box mb={3}>
        {isLoadingLocations ? (
          <Text color={colors.neutrals[200]}>Loading Locations...</Text>
        ) : (
          <Select
            isSearchable
            options={locationOptions}
            name="location"
            placeholder="Select Location"
            onChange={(option) => {
              setNewLocationId(option.value)
            }}
            value={locationOptions?.find(
              (option) => option.value === newLocationId
            )}
          />
        )}
      </Box>
      <Button
        onClick={() => updateShiftLocation(Number(newLocationId))}
        loading={isLoadingLocations || submittingShiftLocation}
      >
        Update Location
      </Button>
    </>
  )
}
