import { useQuery } from '@tanstack/react-query'
import { adminReviewShifts } from './keys'
import { getAdminReviewShiftsForReview } from 'api/review'

export function useAdminReviewShifts() {
  return useQuery({
    queryKey: adminReviewShifts,
    queryFn: getAdminReviewShiftsForReview,
  })
}
