import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export function withRouter(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate()
    const location = useLocation()

    // In v6, 'history' is replaced with 'navigate'
    const history = useMemo(
      () => ({
        push: navigate,
      }),
      []
    )

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        history={history}
      />
    )
  }
}
