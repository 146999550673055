import React from 'react'
import { Heading } from '@workwhile/ui'
import TutorialBuilder from './TutorialBuilder'
import TutorialDisplay from './TutorialDisplay'
import TutorialFileUpload from './TutorialFileUpload'

const TutorialsContainer = () => (
  <div>
    <div>
      <Heading>Build a tutorial</Heading>
      <TutorialBuilder />
      <br />
      <br />
      <hr />
      <Heading>Display tutorial from JSON</Heading>
      <TutorialDisplay />
      <br />
      <br />
      <hr />
      <TutorialFileUpload />
    </div>
  </div>
)

export default TutorialsContainer
