import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import CreateRequirementForPosition from './CreateRequirementForPosition'
import {
  addRequirementToPosition,
  fetchAllRequirementsForParams,
  fetchRequirementData,
  removeRequirementFromPosition,
} from '../../actions/company_actions'
import Loading from '../Shared/loading'

interface Props {
  positionId: number
}
const RequirementsForPosition = ({ positionId }: Props) => {
  const [positionData, setPositionData] = useState([])
  const [positionRequirementData, setPositionRequirementData] = useState([])

  const [allRequirementsDisplayCount, setAllRequirementsDisplayCount] =
    useState(10)
  const [allRequirementsData, setAllRequirementsData] = useState([])

  const [requirementPositionIdParam, setRequirementPositionIdParam] =
    useState(null)
  const [requirementIDParam, setRequirementIDParam] = useState('')
  const [requirementNameParam, setRequirementNameParam] = useState('')

  const [showCreateRequirementModal, setShowCreateRequirementModal] =
    useState(false)

  const [isLoadingCurrRequirements, setIsLoadingCurrRequirements] =
    useState(false)
  const [isLoadingAllRequirementsData, setIsLoadingAllRequirementsData] =
    useState(false)

  useEffect(() => {
    // Fetch all requirements on page load.
    fetchRequirementsForPosition()
  }, [])

  useEffect(() => {
    fetchRequirementsForPosition()
  }, [positionId])

  useEffect(() => {
    fetchAllRequirements()
  }, [
    requirementIDParam,
    requirementNameParam,
    requirementPositionIdParam,
    allRequirementsDisplayCount,
    positionRequirementData,
  ])

  const fetchAllRequirements = () => {
    setIsLoadingAllRequirementsData(true)
    const paramsMappings = [
      {
        condition: requirementIDParam,
        string: `requirementId: ${requirementIDParam}`,
      },
      {
        condition: requirementNameParam,
        string: `nameSubstring: "${requirementNameParam}"`,
      },
    ]

    let params = paramsMappings
      .filter((mapping) => mapping.condition)
      .map((mapping) => mapping.string)
      .join(', ')

    params = params ? `(${params})` : ''

    const responseCallback = (response) => {
      const reqs = response.data.data.admin.allRequirements
      // Filter out the requirements that are already on the position.
      if (positionRequirementData.length > 0) {
        const positionRequirementIds = positionRequirementData.map(
          (requirement) => requirement.id
        )
        let filteredReqs = reqs.filter(
          (requirement) => !positionRequirementIds.includes(requirement.id)
        )
        setAllRequirementsData(filteredReqs)
      } else {
        setAllRequirementsData(reqs)
      }
      setIsLoadingAllRequirementsData(false)
    }
    const errorCallback = (error) => {
      console.log(error)
      setIsLoadingAllRequirementsData(false)
    }

    fetchAllRequirementsForParams(params, responseCallback, errorCallback)
  }

  const fetchRequirementsForPosition = () => {
    setIsLoadingCurrRequirements(true)
    setPositionData([])
    setPositionRequirementData([])

    if (positionId === null) {
      return
    }
    const responseCallback = (response) => {
      setPositionData(response.data.data.admin.positions)

      // Manually extract mustHaveRequirementsData from response instead of storing it separately.
      let requirementData = response.data.data.admin.positions[0].requirements2
      let mustHaveRequirementsData =
        response.data.data.admin.positions[0].mustHaveRequirements
      if (requirementData !== null && mustHaveRequirementsData.length > 0) {
        mustHaveRequirementsData.forEach((mustHaveRequirement) => {
          requirementData.forEach((requirement) => {
            if (mustHaveRequirement.id === requirement.id) {
              requirement.mustHave = true
            }
          })
        })
      }
      setPositionRequirementData(requirementData)
      setIsLoadingCurrRequirements(false)
    }
    const errorCallback = (error) => {
      console.log(error)
      setIsLoadingCurrRequirements(false)
    }
    fetchRequirementData(positionId, responseCallback, errorCallback)
  }

  const positionIdInput = (
    <input
      className="input"
      name="positionId"
      value={requirementPositionIdParam}
      onChange={(e) => setRequirementPositionIdParam(e.target.value)}
      type="text"
      placeholder="Position ID"
    />
  )

  const requirementIdInput = (
    <input
      className="input"
      name="requirementId"
      value={requirementIDParam}
      onChange={(e) => setRequirementIDParam(e.target.value)}
      type="text"
      placeholder="Requirement ID"
    />
  )

  const boolFormatter = (cell, row) => {
    // Returns a checkmark if true, x if false.
    return cell ? <span>✅</span> : <span>❌</span>
  }

  const requirementNameInput = (
    <input
      className="input"
      name="requirementName"
      value={requirementNameParam}
      onChange={(e) => {
        // Reset the number of rows displayed since its a new query now.
        setAllRequirementsDisplayCount(10)
        setRequirementNameParam(e.target.value)
      }}
      type="text"
      placeholder="Requirement Substring"
    />
  )

  const mustHaveColumn = {
    dataField: 'mustHave',
    text: 'Must Have?',
    editable: false,
    formatter: boolFormatter,
  }

  const requirementColumns = [
    {
      dataField: 'id',
      text: 'Requirement ID',
      editable: false,
    },
    {
      dataField: 'name',
      text: 'Requirement Name',
      editable: false,
    },
    {
      dataField: 'category',
      text: 'Category',
      editable: false,
    },
    {
      dataField: 'verifiable',
      text: 'Verifiable?',
      editable: false,
      formatter: boolFormatter,
    },
    {
      dataField: 'isWorkerVisible',
      text: 'Visible to worker',
      editable: false,
      formatter: boolFormatter,
    },
  ]

  function createRequirementButtonColumn(actionType) {
    if (!positionData[0]) return null // Return null if position[0] is not set

    return {
      dataField: 'button',
      text: 'Action',
      editable: false,
      formatter: (cell, row) => {
        const buttonText = actionType
        return (
          <button onClick={() => onRequirementRowClicked(row, actionType)}>
            {buttonText}
          </button>
        )
      },
      headerStyle: { width: '10%' },
    }
  }

  function getRequirementColumns(actionType) {
    const buttonCol = createRequirementButtonColumn(actionType)
    let columns = requirementColumns
    if (buttonCol) {
      columns = [buttonCol, ...columns]
    }
    if (actionType === 'remove') {
      console.log('must have column added')
      // Only associated requirements can be deemed must have or not.
      columns = [...columns, mustHaveColumn]
      console.log(columns)
    }
    return columns
  }

  function onRequirementRowClicked(row, actionType) {
    if (actionType === 'add') {
      const responseCallback = (response) => {
        alert('Requirement added ✅')
        fetchRequirementsForPosition()
      }
      const errorCallback = (error) => {
        alert('Error adding requirement')
        console.log(error)
      }

      // Backend will handle adding a requirement that already is associated with the position. No need to check here.
      addRequirementToPosition(
        positionId,
        row.id,
        responseCallback,
        errorCallback
      )
    } else if (actionType === 'remove') {
      const responseCallback = (response) => {
        alert('Requirement removed 🗑️')
        fetchRequirementsForPosition()
      }

      const errorCallback = (error) => {
        alert('Error removing requirement')
        console.log(error)
      }

      removeRequirementFromPosition(
        positionId,
        row.id,
        responseCallback,
        errorCallback
      )
    } else {
      console.log('Invalid action type:', actionType)
    }
  }

  return (
    <>
      <h5>Current Requirements</h5>
      {isLoadingCurrRequirements ? (
        <Loading />
      ) : (
        <BootstrapTable
          keyField="id"
          data={positionRequirementData}
          columns={getRequirementColumns('remove')}
          striped
          rowStyle={{ fontSize: 14 }}
        />
      )}
      <h5>Search for Existing Requirement</h5>
      Search for requirement by ID, substring, or see all requirements on
      another position. Requirements that are already on the position are
      filtered out.
      <br />
      {requirementNameInput}
      {requirementIdInput}
      {positionIdInput}
      <br />
      {isLoadingAllRequirementsData ? (
        <>Loading...</>
      ) : (
        <>
          <BootstrapTable
            keyField="id"
            data={allRequirementsData.slice(0, allRequirementsDisplayCount)}
            columns={getRequirementColumns('add')}
            striped
            rowStyle={{ fontSize: 14 }}
          />
          <button
            onClick={() =>
              setAllRequirementsDisplayCount(allRequirementsDisplayCount + 10)
            }
            disabled={allRequirementsData.length <= allRequirementsDisplayCount}
          >
            Show More
          </button>
        </>
      )}
      <br />
      <br />
      <h5>
        Create new Requirement{' '}
        <button
          onClick={() => {
            setShowCreateRequirementModal(true)
          }}
        >
          {' '}
          Create ➕{' '}
        </button>
      </h5>
      {showCreateRequirementModal ? (
        <CreateRequirementForPosition
          positionId={positionId}
          successCallback={() => {
            fetchRequirementsForPosition()
          }}
        />
      ) : null}
      <br />
    </>
  )
}

export default RequirementsForPosition
