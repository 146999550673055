import React from 'react'
import { Button, Heading } from '@workwhile/ui'
import TipFileUpload from './TipFileUpload'

const TipsContainer = () => (
  <div className="home">
    <div>
      <Heading>Upload Tip Data</Heading>
      <li>
        Your CSV must include 2 columns named exactly as following: "amount" and
        "phone_number".{' '}
      </li>
      <li>
        Additional columns will be disregarded. The phone_number can be in any
        format.
      </li>
      <li>The amount must be in the form 1.00 for a $1 tip.</li>
      <li>Please remove any currency symbols.</li>
      <Button
        mt={3}
        onClick={() => {
          window.open(
            'https://firebasestorage.googleapis.com/v0/b/workwhile-247820.appspot.com/o/public%2F3%2F8858aea8-bbb3-4a6c-9297-c9b5c4fe2ad6-08-05-24?alt=media&token=f553f7b5-7ae3-467e-8fa6-5ac91b0fde24'
          )
        }}
      >
        Download Example Tips File
      </Button>
      <br />
      <br />
      <TipFileUpload />
    </div>
  </div>
)

export default TipsContainer
