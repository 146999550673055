import { useMutation } from '@tanstack/react-query'
import { uploadProfilePic } from 'api/user'

export function useUploadProfilePicMutation(
  userId: string,
  manually_verify: boolean,
  options?: {
    onSuccess?: () => void
    onError?: (error: Error) => void
  }
) {
  return useMutation({
    mutationFn: (file: File) => uploadProfilePic(userId, file, manually_verify),
    ...options,
  })
}
