import React from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { Box, Button } from '@workwhile/ui'
import { LogOutIcon } from 'lucide-react'

export default function SignOutButton() {
  return (
    <Box m={3}>
      <Button
        variant={'primary'}
        onClick={() => {
          const auth = getAuth()
          signOut(auth)
        }}
      >
        <LogOutIcon size={18} />
      </Button>
    </Box>
  )
}
