import React from 'react'
import { User } from '../Shared/common_defs'
import CollapsibleSection from '../Shared/CollapsibleSection'
import { Table, Text } from '@workwhile/ui'

interface Props {
  user: User
}

const UserReferralInfo = ({ user }: Props) => {
  const referralData = React.useMemo(() => {
    return user.referrals.map((referral) => {
      return {
        // null check since the referrer might have removed name.
        referralName: referral.referred ? referral.referred.name : 'No Name',
        referralId: referral.id,
        referralStatus: referral.status,
      }
    })
  }, [user.referrals])

  const referralColumns = React.useMemo(
    () => [
      {
        header: 'Refferal Name',
        accessorKey: 'referralName',
      },
      {
        header: 'Referral ID',
        accessorKey: 'referralId',
      },
      {
        header: 'Referral Status',
        accessorKey: 'referralStatus',
      },
    ],
    []
  )

  const refereeData = React.useMemo(() => {
    return user.reference
      ? [
          {
            refereeName: user.reference.referrer?.name,
            refereeId: user.reference.referrer?.id,
          },
        ]
      : []
  }, [user.reference])

  const refereeColumns = React.useMemo(
    () => [
      {
        header: 'Referee Name',
        accessorKey: 'refereeName',
      },
      {
        header: 'Referee ID',
        accessorKey: 'refereeId',
      },
    ],
    []
  )

  return (
    <CollapsibleSection title="Worker Referral Info">
      {user.referrals && user.referrals.length > 0 ? (
        <Table data={referralData} columns={referralColumns} />
      ) : (
        <Text my={3} color={'lightText'}>
          User has no referrals
        </Text>
      )}
      {user.reference ? (
        <Table data={refereeData} columns={refereeColumns} />
      ) : (
        <Text my={3} color={'lightText'}>
          User was not referred by anyone.
        </Text>
      )}
    </CollapsibleSection>
  )
}

export default UserReferralInfo
