export const WILL_OR_HAS_WORKED_STATUSES = [
  'scheduled',
  'started',
  'completed',
  'admin_review',
  'employer_review',
  'employer_approved',
  'approved',
  'paid',
]
