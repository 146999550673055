import { useQuery } from '@tanstack/react-query'
import { WorkerAvailabilityRequest, getWorkerAvailability } from 'api/user'
import { workerAvailabilityKey } from './keys'

export function useWorkerAvailability({
  shiftId,
  workerIds,
}: WorkerAvailabilityRequest) {
  return useQuery({
    queryKey: workerAvailabilityKey({
      shiftId,
      workerIds,
    }),
    queryFn: () =>
      getWorkerAvailability({
        shiftId,
        workerIds,
      }),
    staleTime: 2 * 60 * 1000, // 2 minute stale time
  })
}
