import React, { useState } from 'react'
import api from '../../api'
import * as moment from 'moment-timezone'
import Loading from '../Shared/loading'
import { Strike, User } from '../Shared/common_defs'
import { dateTimeSubmittedFormat, getUserTimezone } from '../utils/general_util'
import { Table } from '@workwhile/ui'

interface Props {
  user: User
  strike: Strike
  refreshData: () => void
}

const UserStrike = ({ user, strike, refreshData }: Props) => {
  const [reason, setReason] = useState('')
  const [submittingWaiveStrike, setSubmittingWaiveStrike] = useState(false)

  const _timezone = getUserTimezone(user)

  const data = React.useMemo(
    () => [
      {
        key: 'Strike ID',
        value: strike.id,
      },
      {
        key: 'Strike Date',
        value: strike.date
          ? moment.tz(strike.date, _timezone).format(dateTimeSubmittedFormat)
          : null,
      },
      {
        key: 'Strike Policy',
        value: strike.reason,
      },
      {
        key: 'Strike Excuse',
        value: strike.excuse,
      },
      {
        key: 'Shift Id',
        value: strike.shiftId,
      },
      {
        key: 'Cancelled For Illness (not necessarily failed health check)',
        value: strike.isCancelledForIllness ? 'true' : 'false',
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'key',
      },
      {
        accessorKey: 'value',
      },
    ],
    []
  )

  const submitWaiveStrike = (user) => {
    if (!reason || reason === '') {
      window.alert('you must provide a reason for waiving the strike')
      return
    }
    setSubmittingWaiveStrike(true)

    const r = window.confirm(
      `You are about to wave USER(${user.id})'s strike ${strike.id}. Reason: ${reason}. To execute this action, please press Ok.`
    )

    if (r == true) {
      api
        .post(`/admin/user/strike_waive`, {
          strike_id: strike.id,
          reason: reason,
        })
        .then((response) => {
          alert(`Strike waived for user(${user.id})`)
          setSubmittingWaiveStrike(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingWaiveStrike(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingWaiveStrike(false)
    }
  }

  return (
    <div>
      <Table data={data} columns={columns} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 5 }}>Reason for waiving:</p>
        <input
          className="input"
          name="reason"
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          type="text"
          placeholder="Reason"
          style={{ fontSize: 12 }}
        />
      </div>
      {!submittingWaiveStrike && (
        <button
          onClick={() => submitWaiveStrike(user)}
          className="confirm-button"
        >
          Waive Strike
        </button>
      )}
      {submittingWaiveStrike && <Loading type="button" />}
      <hr />
    </div>
  )
}

export default UserStrike
