// @ts-nocheck
import React, { useState } from 'react'
import { defs } from './defs'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Option from 'react-select/src/types'
import styled from 'styled-components'
import { device } from './device'

interface ShiftsFilterBar2Props {
  companyIds: number[]
  shiftId: number
  fromDate: string
  toDate: string
  market: string
  showUnfilledShiftsOnly: boolean
  showCancelledShiftsOnly: boolean
  companyOptions: any
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const animatedComponents = makeAnimated()

const ShiftsFilterBar2: React.FC<ShiftsFilterBar2Props> = ({
  companyIds,
  shiftId,
  fromDate,
  toDate,
  market,
  showUnfilledShiftsOnly,
  showCancelledShiftsOnly,
  companyOptions,
  handleInputChange,
  handleCheckboxChange,
}) => {
  const [companyOptionsFormatted, setCompanyOptionsFormatted] = useState(
    companyOptions
      ? companyOptions.map(
          (company): Option => ({
            label: `${company.name} (${company.id})`,
            value: company.id,
          })
        )
      : null
  )

  const handleCompanySelectChange = (selectedOptions, actionMeta) => {
    console.log(selectedOptions, actionMeta)
    const fakeElement = {
      target: {
        name: 'companyIds',
        value: selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [],
      },
    }
    // @ts-ignore
    handleInputChange(fakeElement)
  }

  return (
    <FilterBarWrapper showBorderTop={true} showBorderBottom={true}>
      <SelectGroup>
        <Select
          isMulti
          isSearchable
          isClearable
          options={companyOptionsFormatted}
          components={animatedComponents}
          styles={customStyles}
          name="company"
          placeholder="Company"
          onChange={handleCompanySelectChange}
        />
        <Instruction>&</Instruction>

        <input
          type="text"
          name="market"
          value={market}
          onChange={handleInputChange}
          placeholder={'market'}
        />
        <Instruction>&</Instruction>
        <input
          type="number"
          name="shiftId"
          value={shiftId}
          onChange={handleInputChange}
          placeholder={'Shift ID'}
        />
        <Instruction>&</Instruction>
        <>
          <span
            style={{
              marginLeft: 5,
              marginRight: 5,
              color: 'rgba(0,0,0,0.5)',
              fontSize: defs.fontS,
            }}
          ></span>
          <input
            type="date"
            name="fromDate"
            value={fromDate}
            onChange={handleInputChange}
          />
          <span
            style={{
              marginLeft: 5,
              marginRight: 5,
              color: 'rgba(0,0,0,0.5)',
              fontSize: defs.fontS,
            }}
          >
            to
          </span>
          <input
            type="date"
            name="toDate"
            value={toDate}
            onChange={handleInputChange}
          />
        </>
        <Instruction>&</Instruction>
        <label style={{ marginBottom: 0, marginLeft: 10, marginRight: 10 }}>
          <input
            type="checkbox"
            name="showCancelledShiftsOnly"
            checked={showCancelledShiftsOnly}
            onChange={handleCheckboxChange}
          />
          Show Cancelled Shifts Only
        </label>
        <Instruction>&</Instruction>
        <label style={{ marginBottom: 0, marginLeft: 10, marginRight: 10 }}>
          <input
            type="checkbox"
            name="showUnfilledShiftsOnly"
            checked={showUnfilledShiftsOnly}
            onChange={handleCheckboxChange}
          />
          Show Unfilled Shifts Only
        </label>
      </SelectGroup>
    </FilterBarWrapper>
  )
}

export default ShiftsFilterBar2

const customStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 220,
    marginRight: defs.marginM,
    marginTop: defs.marginXS,
    marginBottom: defs.marginXS,
    fontSize: defs.fontS,
  }),
}

const FilterBarWrapper = styled.div`
  border-bottom: ${(props) => (props.showBorderBottom ? '1px solid' : null)};
  border-top: ${(props) => (props.showBorderTop ? '1px solid' : null)};
  border-color: ${defs.veryLightBlack};
  padding: ${defs.paddingS};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  @media ${device.smallerThanMobileL} {
    font-size: ${defs.fontS};
    height: 100%;
    padding-left: ${defs.paddingM};
  }
`

const Instruction = styled.div`
  color: ${defs.mediumBlack};
  margin-right: ${defs.marginM};
  margin-left: ${defs.marginM};
  font-size: ${defs.fontS};
`

const SelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`
