import React from 'react'

import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import api from '../../api'
import userAction from '../../actions/user_actions'
import { Modal } from 'react-bootstrap'
import './features.css'

const INITIAL_STATE = {
  users: [],
}

/*
const rowStyle = (row, rowIndex) => {
    console.log("ROW VALUE",row)
    return { color: 'red'  };
};
*/

class ScreenView extends React.Component {
  constructor(props) {
    super(props)
    this.getcolumns = this.getcolumns.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.approveScreening = this.approveScreening.bind(this)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.fetchScreeningUsers()
  }

  approveScreening(id) {
    userAction('approve_screening', id)
      .then(() => {
        this.fetchScreeningUsers()
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  getcolumns() {
    return [
      {
        dataField: 'id',
        text: 'Id',
        headerStyle: { width: '5em' },
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerStyle: { width: '10em' },
      },
      {
        dataField: 'address',
        text: 'Address',
        headerStyle: { width: '15em' },
      },
      {
        dataField: 'has_car',
        text: 'Car?',
        headerStyle: { width: '10em' },
      },
      {
        dataField: 'has_dl',
        text: 'License?',
        headerStyle: { width: '10em' },
      },
      {
        dataField: 'currently_employed',
        text: 'Employed?',
        headerStyle: { width: '10em' },
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: (cell, row) => {
          return (
            <div>
              <button
                key={'activate_' + row.id}
                onClick={() =>
                  window.open(
                    'https://console.cloud.google.com/storage/browser/outfielders/' +
                      row.id,
                    '_blank'
                  )
                }
              >
                See Videos
              </button>
              <button
                key={'approve_' + row.id}
                onClick={() => this.approveScreening(row.id)}
              >
                Approve
              </button>
              <button
                key={'notes_' + row.id}
                onClick={() =>
                  this.setState({ showNoteModal: true, currentUserId: row.id })
                }
              >
                Add Phone interview note
              </button>
            </div>
          )
        },
      },
    ]
  }

  fetchScreeningUsers() {
    api.get('/admin/screen').then((response) => {
      this.setState({
        users: response.data,
      })
    })
  }

  handleClose() {
    this.setState({ showNoteModal: false })
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { currentUserId, currentNote } = this.state
    api
      .post(`/admin/user/${currentUserId}/note`, {
        note: currentNote,
      })
      .then(() => {
        this.setState({
          showNoteModal: false,
          currentNote: null,
          currentUserId: null,
        })
      })
  }

  render() {
    const { users, currentNote, currentUserId, showNoteModal } = this.state
    let columns = this.getcolumns()

    return (
      <div className="users-table mt-5">
        <Heading>Users who have submitted screening</Heading>

        <BootstrapTable
          striped
          hover
          keyField="name"
          data={users}
          columns={columns}
        />

        <Modal show={showNoteModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Phone interview notes</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.onSubmit}>
            <textarea
              className="phone-interview-input"
              name="currentNote"
              value={currentNote}
              onChange={this.onChange}
              type="text"
              placeholder="Phone interview notes"
            />
            <button type="submit" className="ml-2">
              Submit
            </button>
          </form>
        </Modal>
      </div>
    )
  }
}

export default ScreenView
