import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { defs } from '../Shared/defs'
import UserBasicInfo from './UserBasicInfo'
import UserWorkProfile from './UserWorkProfile'
import UserLogUrls from './UserLogUrls'
import UserIdentityVerfification from './UserIdentityVerification'
import UserHeader from './UserHeader'
import UserCompanyWorkerProfile from './UserCompanyWorkerProfile'
import UserWork from './UserWork'
import UserEvents from './UserEvents'
import { User } from '../Shared/common_defs'
import UserJobRequests from './JobRequests/UserJobRequests'
import UserReferralInfo from './UserReferralInfo'

interface Props {
  user: User
  search: (e?: any) => void
}

const UserRecord = ({ user, search }: Props) => {
  return (
    <div className="mt-2 mb-5">
      <UserHeader user={user} />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: '100%',
          marginTop: defs.marginM,
        }}
      >
        <UserBasicInfo user={user} refreshData={search} />
        <UserWorkProfile user={user} />
        <UserIdentityVerfification user={user} refreshData={search} />
        <UserLogUrls user={user} />
        <UserCompanyWorkerProfile user={user} />
        <UserEvents user={user} />
        <UserJobRequests user={user} />
        <UserReferralInfo user={user} />
      </div>

      <hr />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: defs.marginM,
          marginBottom: defs.marginXL,
        }}
      >
        <UserWork user={user} />
      </div>
    </div>
  )
}

export default UserRecord
