import { useMutation } from '@tanstack/react-query'
import { updateLTAShiftLocation } from 'api/lta'

export function useUpdateLTAShiftLocationMutation(
  shiftId: number,
  options?: {
    onSuccess?: () => void
    onError?: (error: Error) => void
  }
) {
  return useMutation({
    mutationFn: (locationId: number) =>
      updateLTAShiftLocation(shiftId, locationId),
    ...options,
  })
}
