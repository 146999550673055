import React, { useEffect, useMemo, useState } from 'react'
import { useLeftEarlyShifts } from 'queries/review'
import {
  Loading,
  createColumnHelper,
  Table,
  Link,
  Box,
  Text,
} from '@workwhile/ui'
import { Work } from 'api/typings'
import moment from 'moment/moment'
import WorkerDetail from 'components/Shifts/Workers/WorkerDetail'
import { format } from 'date-fns'

const columnHelper = createColumnHelper<Work>()

export function LeftEarlyQueue() {
  const { data, isLoading, refetch } = useLeftEarlyShifts()
  const [workList, setWorkList] = useState<Work[]>([])

  useEffect(() => {
    setWorkList(data || [])
  }, [data])

  const onActionSuccess = (action: string) => {
    if (action === 'mark_approved') {
      refetch()
    }
  }

  const removeShiftFromReviewList = (workId: number) => {
    setWorkList((prev) =>
      prev.filter((work) => work.id.toString() !== workId.toString())
    )
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'Work ID',
        enableSorting: false,
      }),
      columnHelper.accessor('worker', {
        header: 'Worker',
        cell: (info) => {
          const worker = info.getValue()
          return (
            <Box>
              <Link to={`/users/${worker.id}`} target={'_blank'}>
                {worker.name}
              </Link>
              <Box>
                <Text>{worker.id}</Text>
              </Box>
            </Box>
          )
        },
      }),
      columnHelper.accessor('shift', {
        header: 'Shift Info',
        enableSorting: false,
        cell: (info) => {
          const cell = info.getValue()
          return (
            <>
              <p>
                {cell.id} at {cell.company.name}
              </p>
              <p>
                Position: ({cell.position.id}) {cell.position.name}
              </p>
              <p>
                Location: ({cell.location.id}) {cell.location.name}{' '}
              </p>
              <p>
                Times: {moment(cell.startsAt).format('YYYY-MM-DD h:mm A')} -{' '}
                {moment(cell.endsAt).format('YYYY-MM-DD h:mm A')}
              </p>
              <p>Lunch (mins): {cell.lunchLength}</p>
              <p>Supervisor(s)</p>
              {cell.supervisors?.map((supervisor) => (
                <p key={supervisor.id}>
                  {supervisor.name}. Phone: {supervisor.phoneNumber || 'N/A'}
                </p>
              ))}
            </>
          )
        },
      }),
      columnHelper.accessor('earnings', {
        header: 'Pay',
        enableSorting: false,
        cell: (info) => <Text>${info.getValue()}</Text>,
      }),
      columnHelper.accessor('startedAt', {
        header: 'Started',
        size: 120,
        sortingFn: 'datetime',
        cell: (info) => {
          if (!info.getValue()) {
            return 'N/A'
          }
          const date = new Date(info.getValue())
          return (
            <>
              <Box>{format(date, 'yyyy-MM-dd')}</Box>
              <Box>{format(date, 'hh:mm a')}</Box>
            </>
          )
        },
      }),
      columnHelper.accessor('completedAt', {
        header: 'Completed',
        size: 120,
        sortingFn: (a, b) => {
          const dateA = new Date(a.original.completedAt)
          const dateB = new Date(b.original.completedAt)
          // sort date, nulls first
          // If both dates are null, use Id
          if (!dateA && !dateB) {
            return Number(a.original.id) - Number(b.original.id)
          }

          // If dateA is null, it should come first
          if (!dateA) return -1

          // If dateB is null, it should come first
          if (!dateB) return 1

          // If neither date is null, compare them as usual
          return dateA.getTime() - dateB.getTime()
        },
        cell: (info) => {
          if (!info.getValue()) {
            return 'N/A'
          }
          const date = new Date(info.getValue())
          return (
            <>
              <Box>{format(date, 'yyyy-MM-dd')}</Box>
              <Box>{format(date, 'hh:mm a')}</Box>
            </>
          )
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
          const status = info.getValue()
          if (status === 'employer_review') {
            return <Text fontWeight={2}>Waiting for employer review</Text>
          }
          return <Text fontWeight={2}>{status}</Text>
        },
      }),
      columnHelper.accessor('reasonEarlyClockout', {
        header: 'Early Clockout reason',
      }),
      columnHelper.accessor('id', {
        header: 'Actions',
        enableSorting: false,
        cell: (info) => {
          const record = info.row.original
          return (
            <WorkerDetail
              shift={record.shift}
              workId={record.id}
              workerId={record.worker.id}
              fetchWorkers={null}
              fetchWorkStats={null}
              onActionSuccess={onActionSuccess}
              removeShiftFromReviewList={() =>
                removeShiftFromReviewList(record.id)
              }
            />
          )
        },
      }),
    ],
    []
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <Table
      columns={columns}
      data={workList}
      textVerticalAlign={'top'}
      enableSorting={true}
      defaultSort={[
        {
          id: 'completedAt',
          desc: false,
        },
      ]}
      alternateRowColor={true}
    />
  )
}
