import api from 'api'

export interface HypotheticalShift {
  start: string
  end: string
  positionId: number
  locationId: number
}

export interface WorkerAvailabilityRequest {
  workerIds: number[]
  shiftId: number
}

export interface WorkerAvailability {
  workerId: number
  shift: HypotheticalShift
  available: boolean
  reasons?: string[]
}

export const getWorkerAvailability = async ({
  workerIds,
  shiftId,
}: WorkerAvailabilityRequest) => {
  // if any fields are missing, quietly terminate
  if (!workerIds || !shiftId) {
    return
  }

  const data = await api.post(`/admin/shift/${shiftId}/worker_availability`, {
    worker_ids: workerIds,
  })
  const availabilities = data.data.worker_availabilities

  return availabilities.map((availability) => ({
    workerId: availability.worker_id,
    shift: availability.shift,
    available: availability.available,
    reasons: availability.reasons,
  })) as WorkerAvailability[]
}
