import React, { useState } from 'react'
import api from '../../../api'
import Loading from '../../Shared/loading'
import { Listing } from '../../Shared/common_defs'

interface Props {
  listing: Listing
  fetchListings: () => void
}

const ListingNextTier = ({ listing, fetchListings }: Props) => {
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    const r = window.confirm(
      `You are about to enqueue a tier transition for listing ${listing.id}.\n\n THE TIER TRANSITION MAY TAKE UP TO 2 MINUTES TO RUN.\n\n To execute this action, please press Ok.`
    )
    if (r == true) {
      api
        .post(`/admin/listing/${listing.id}/next`)
        .then((response) => {
          console.log(response)
          fetchListings()
          alert(`Enqueued tier transition for listing ${listing.id}`)
          setSubmitting(false)
        })
        .catch((error) => {
          setSubmitting(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmitting(false)
    }
  }

  if (listing.tier === 'all') return null

  return (
    <div>
      <hr />
      <b>Listing Next Tier</b>
      <p>
        Triggers moving listing to the next tier. (Current tier:{' '}
        <b>{listing.tier}</b>)
      </p>
      {!submitting && (
        <button
          className="confirm-button"
          onClick={onSubmit}
          type="submit"
          style={{ display: 'inline-block', width: '100%' }}
        >
          Move to next tier
        </button>
      )}
      {submitting && <Loading type="button" />}
      <hr />
    </div>
  )
}

export default ListingNextTier
