import React from 'react'
import * as moment from 'moment-timezone'

export const eventLogQuery = (
  userId: string,
  startsAt: string | null,
  endsAt: string | null
) => {
  return `
        admin { 
            eventLogs (
                userId: ${userId}
                ${startsAt ? `, startsAt: "${startsAt}"` : ''}
                ${endsAt ? `, endsAt: "${endsAt}"` : ''}
            ) {
                timestamp, message, userId, companyId, shiftId, subjectUserId, eventType,
            }
        }
    `
}

export const getEventLogsColumns = (timezone) => {
  return [
    {
      dataField: 'timestamp',
      text: 'Time',
      sort: true,
      headerStyle: { width: '4em', fontSize: 12 },
      formatter: (cell, row) => {
        return (
          <p>{moment.tz(cell, timezone).format('ddd Do MM YYYY HH:mma z')}</p>
        )
      },
    },
    {
      dataField: 'message',
      text: 'Message',
      sort: true,
      headerStyle: { width: '10em', fontSize: 12 },
    },
    {
      dataField: 'shiftId',
      text: 'Details',
      sort: true,
      headerStyle: { width: '5em', fontSize: 12 },
      formatter: (cell, row) => {
        return (
          <>
            <p>Shift ID: {cell}</p>
            <p>Company ID: {row['companyId']}</p>
          </>
        )
      },
    },
  ]
}
