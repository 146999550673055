import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Heading } from '@workwhile/ui'

function TutorialFileUpload({ firebase }) {
  const [file, setFile] = useState(null)
  const [filename, setFilename] = useState('')
  const [uploading, setUploading] = useState(false)
  const [downloadURL, setDownloadURL] = useState('')
  const [folder, setFolder] = useState('')

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleFilenameChange = (e) => {
    setFilename(e.target.value)
  }

  const uploadFile = async () => {
    if (!file || !filename || !folder) return
    const storage = getStorage()
    const uniqueFilename = uuidv4() + '-' + filename
    const fileRef = ref(storage, `public/${folder}/${uniqueFilename}`)
    setUploading(true)
    // Make the file publicly readable and set metadata
    const metadata = {
      cacheControl: 'public, max-age=31536000',
      contentDisposition: `inline; filename="${filename}"`,
      customMetadata: {
        public: 'true',
      },
    }
    await uploadBytes(fileRef, file, metadata)
    const url = await getDownloadURL(fileRef)
    setDownloadURL(url)
    setUploading(false)
  }

  return (
    <div>
      <Heading>Tutorial File Uploader</Heading>
      <input type="file" onChange={handleFileChange} />
      <input
        type="text"
        placeholder="Enter a filename"
        value={filename}
        onChange={handleFilenameChange}
      />
      <input
        type="text"
        placeholder="Folder name (company ID)"
        value={folder}
        onChange={(e) => setFolder(e.target.value)}
      />
      <button onClick={uploadFile} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      {downloadURL && (
        <p>
          File uploaded successfully!{' '}
          <a href={downloadURL} target="_blank" rel="noreferrer">
            Click here to view the file
          </a>
        </p>
      )}
    </div>
  )
}

export default TutorialFileUpload
