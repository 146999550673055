import React from 'react'
import { Strike, User } from '../Shared/common_defs'
import { dateTimeSubmittedFormat, getUserTimezone } from '../utils/general_util'
import moment from 'moment-timezone'
import { Box } from '@workwhile/ui'

interface Props {
  user: User
  strike: Strike
}

export const UserPastStrike = ({ user, strike }: Props) => {
  const _timezone = getUserTimezone(user)
  return (
    <Box my={3}>
      ({strike.id}) <strong>{strike.reason}</strong> {strike.excuse}{' '}
      {strike.date
        ? moment.tz(strike.date, _timezone).format(dateTimeSubmittedFormat)
        : null}{' '}
      Excluded: {String(strike.excluded)}
    </Box>
  )
}
