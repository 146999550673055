import React from 'react'
import { User, WorkShift } from 'api/typings'
import { useWorkerAvailability } from 'queries/user/useWorkerAvailability'
import { Loading, Box, Text, Tooltip, Flex, useAppTheme } from '@workwhile/ui'
import { Info } from 'lucide-react'
import { WorkerUnavailableReasons } from './WorkerUnavailableReasons'
import { WILL_OR_HAS_WORKED_STATUSES } from 'lib/work'

interface Props {
  worker: User
  shift: WorkShift
}

export const RequestedWorkerStatus = ({ worker, shift }: Props) => {
  const { data: workerAvailabilities, isLoading } = useWorkerAvailability({
    shiftId: shift.id,
    workerIds: [Number(worker.id)],
  })
  const { colors } = useAppTheme()

  if (isLoading) {
    return <Loading type="button" />
  }

  const availabilitiesForWorker = workerAvailabilities?.filter(
    (availability) => Number(availability.workerId) === Number(worker.id)
  )

  // if the worker is already scheduled for the shift, return 'Accepted'
  if (
    shift?.work?.some(
      (work) =>
        work.worker?.id === worker.id &&
        WILL_OR_HAS_WORKED_STATUSES.includes(work.status)
    )
  ) {
    return (
      <Text fontSize={1} fontStyle="italic" color="success">
        Accepted
      </Text>
    )
  }

  if (
    !availabilitiesForWorker ||
    availabilitiesForWorker === undefined ||
    availabilitiesForWorker.length < 0
  ) {
    return null
  }

  if (
    availabilitiesForWorker?.every((availability) => availability.available)
  ) {
    return (
      <Text fontSize={1} fontStyle="italic" color="warning200">
        Awaiting response
      </Text>
    )
  }

  const renderAvailabilityStatus = (
    availableCount: number,
    totalDays: number
  ) => {
    const statusText =
      availableCount === 0
        ? `Unavailable${totalDays > 1 ? ' all days' : ''}`
        : `Available ${availableCount} of ${totalDays} days`
    const iconSize = availableCount === 0 ? 14 : 10

    return (
      <Box>
        <Tooltip
          content={
            <WorkerUnavailableReasons
              worker={worker}
              availabilitiesForWorker={availabilitiesForWorker}
            />
          }
        >
          <Flex flexDirection="row" flex={1} alignItems="center">
            <Text
              fontSize={1}
              mr={1}
              color="info"
              style={{ textDecoration: 'underline' }}
            >
              {statusText}
            </Text>
            <Info size={iconSize} color={colors.info} />
          </Flex>
        </Tooltip>
      </Box>
    )
  }

  const availableCount = availabilitiesForWorker.filter(
    (availability) => availability.available
  ).length
  const totalDays = availabilitiesForWorker.length
  return renderAvailabilityStatus(availableCount, totalDays)
}
