export const userKey = ['review']
export const leftEarlyShifts = [...userKey, 'leftEarlyShifts']
export const adminReviewShifts = [...userKey, 'adminReviewShifts']
export const ltaShiftKey = (shiftId: number) => [
  ...userKey,
  'ltaScreeningShift',
  shiftId,
]
export const companyLocationsKey = (companyId: number) => [
  ...userKey,
  'companyLocations',
  companyId,
]
