import {useQuery} from "@tanstack/react-query";
import graphql from "../graphql";
import api from '../api';

export const MessageThreads = ({ children, searchParam, unread }) => {
  console.log('searchParam: ', searchParam)
  const req = () => threadsRequest({searchParam, unread});
  const threads = useQuery('threads', req);
  const { isLoading, isFetching, data } = threads;
  console.log('threads: ', threads)
  return children({ data: data?.data?.data ? data.data.data : {}, isFetching, isLoading });
};

export const workerChatMessagesRequest = (workerId) => graphql(
  `admin {
          workerChatMessages(workerId: ${workerId}) {
            items {
              direction
              message
              fromUser { id, name, profilePicUrl }
              toUser { id, name, profilePicUrl }
              shift { id, startsAt, endsAt, location {name}, position {name} }
              createdAt
            }
          }
        }`,
  'messaging',
);

export const threadsRequest = ({
  searchParam, unread, cursor, shiftId, automated, manual
}) => graphql(`admin {
          latestChatMessages (
            searchParam: "${searchParam}"
            ${unread ? `unread: ${unread}` : ''}
            ${automated ? `automated: ${automated}` : ''}
            ${manual ? `manual: ${manual}` : ''}
            ${cursor ? `cursor: "${cursor}"`: ''}
            ${shiftId ? `shiftId: ${shiftId}` : ''}
          ) {
            cursor
            items {
              unreadCount
              direction
              message
              createdAt
              fromUser { id, name, profilePicUrl, phoneNumber, onboardingStatus { checkrStatus, orientationStatus, status } }
              toUser { id, name, profilePicUrl, phoneNumber, onboardingStatus { checkrStatus, orientationStatus, status } }
              shift { id, startsAt, location {name, address {city}}, position {name}, company {id, name, logoUrl} }
            }
          }
        }`);

export const sendMessage = (user_ids, message, shift_id) => {
  const payload = shift_id ? { user_ids, message, shift_id } : { user_ids, message }
  return api.post(`/admin/users/chat`, payload);
}

export const getCallList = (shiftId) => api.get(`/admin/shifts_call_list/${shiftId}`);

export const markChatAsRead = (user_ids) => api.post(`/admin/users/chat_read`, {user_ids});

export const getRecentShiftDescriptions = () => api.get('/admin/messages_shift_descriptions');
